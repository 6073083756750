import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { TezosProvider } from "./context/TezosContext";
import { APIProvider } from './context/APIContext';
import { StyledEngineProvider } from '@mui/material';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";



import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
  uri: 'https://data.objkt.com/v3/graphql',

  cache: new InMemoryCache()

});


export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}




ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>

      <BrowserRouter>
        <APIProvider>
          <ScrollToTop />
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </APIProvider>
      </BrowserRouter>
    </StyledEngineProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
