const questions =
    [
        {
            "title": "WHAT IS C-VERSO",
            "content": "C-VERSO is a curated NFT platform with a focus on Generative Art base on the Tezos Blockchain."
        },
        {
            "title": "THE GOAL OF C-VERSO",
            "content": "We believe Generative Art is one of the most interesting artistic movement of this decade and thanks to NFTs and Blockchain technologies it can be developed and divulgate at its best. Our goal is to exhibit curated show in the major contemporary Art Fairs around the world and in our gallery spaces."
        },
        {
            "title": "WHAT ARE THE CHAOS BUTTON?",
            "content": "Chaos let the Collector interact with the Artwork changing all or some selected parameters chosen by the Artist. You have a limited number of each every day, and they will replenish after minting."
        },
        {
            "title": "WHY ARE THE CHAOS LIMITED?",
            "content": "Those uses are limited because the platform is a project for physical art exhibitions where the visitors could not stay forever clicking the platform and have to give the spot to other visitors after a few clicks so we decided to have the uses limited also for collectors online."
        },
        {
            "title": "WHERE MY NFT WILL BE MINTED",
            "content": "C-VERSO mints directly on Objkt.com in the C-VERSO collection with a standard FA2 smart contract (like ERC-721 on Ethereum ). The creator of the NFT will be the Artist and the owner will be the buyer."
        },
        {
            "title": "HOW OUR DROPS WORK?",
            "content": "NFTs from our drops are sold directly by the Artist. We also leave the opportunity to the artist to make a Curated series instead of a generative art drop."
        },
        {
            "title": "ARE ROYALTIES PAID TO THE ARTISTS?",
            "content": "Yes, the Artist will receive the royalties every time their NFTs are sold and bought on the secondary market."
        },
        {
            "title": "MARKETPLACE",
            "content": "For now, we do not plan to have our Marketplace, we prefer to focus on curating our exhibitions and drops, you can trade the minted NFTs on Objkt.com, we think it is a fantastic platform to use as a Marketplace since it links many different platforms with thousands of Artists and Collectors.",
        },
        {
            "title": "WHY TEZOS?",
            "content": "We decided to use Tezos because there is a strong community of Generative Artists, we like low gas fees for minting, offering everyone the chance of collecting beautiful art made by talented Artists. We also love #CleanNFTs, we think there is no need to use a PoW Blockchain to create NFTs.",
        },


        {
            "title": "DISCORD SERVER",
            "content": "We are working on opening our Discord Server, for now you can ask any question and help with issues with a private message on Twitter @CortesiVerso.",
        }
    ];


export default questions;