import { Container, Grid, Box, Link, Typography } from "@mui/material"
import "../index.css"

import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
    return (
        <Box className="footer" sx={{ backgroundColor: "primary.main", pb: 12.5, pt: 6.25, mt: 12.5 }} >
            <Container maxWidth="lg" >
                <Box>
                    <Grid container >
                        <Grid xs={12} sm={8} item >

                            <Typography className="claim-footer-1" sx={{
                                textTransform: "uppercase",
                                fontWeight: 700,
                                color: "#EEF2EC",
                                fontSize: "18px",
                            }} >The path between generative</Typography>
                            <Typography className="claim-footer-2" sx={{
                                textTransform: "uppercase",
                                fontWeight: 700,
                                color: "#bfd3d8",
                                fontSize: "18px",
                            }}>and contemporary art.</Typography>

                            <div className="social-row">

                                <Link target="_blank"
                                    color={"background.default"}
                                    href="https://www.twitter.com/CortesiVerso"
                                    sx={{ mr: 1.5 }}>
                                    <TwitterIcon />
                                </Link>
                                <Link target="_blank"
                                    color={"background.default"}
                                    href="https://www.instagram.com/cortesigallery/"  >
                                    <InstagramIcon />
                                </Link>
                            </div>

                        </Grid>

                        <Grid xs={12} sm={4} item className="claim-footer-2">

                            <Typography><Link target="_blank"
                                href="https://docs.google.com/forms/d/e/1FAIpQLSdPA9rMfh9OJOiqMPKPwuOAen5lUu1iPQNwS1RVexvV27ux1g/viewform?vc=0&c=0&w=1&flr=0"
                                underline="none"
                                color={"background.default"} >
                                Apply as an artist</Link></Typography>
                            <Typography ><Link target="_blank"
                                href="mailto:info@cverso.io"
                                underline="none"
                                color={"background.default"} >
                                Support</Link></Typography>

                            <Typography><Link href="/faq"
                                underline="none"
                                color={"background.default"} >
                                FAQ</Link></Typography>
                        </Grid>



                    </Grid>
                </Box>
            </Container>
        </Box >
    )
}

export default Footer;