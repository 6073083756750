import { Grid, Typography, Link, Stack, Paper, Container, Button, ImageList, ImageListItem, Divider, Box, IconButton, CircularProgress, Tooltip, Backdrop, Stepper, Step, StepLabel, Snackbar, Skeleton, Switch, FormControlLabel, FormGroup, SnackbarContent, TablePagination, Pagination, ToggleButtonGroup, ToggleButton, styled } from "@mui/material";

import { useState, useEffect, useRef } from 'react';
import GenerativeFrame from "./GenerativeFrame.js";
import { CssBaseline, ThemeProvider } from "@mui/material";

import MuiAlert from '@mui/material/Alert';
import { char2Bytes } from "@taquito/utils";
import axios from "axios";
import Image from 'material-ui-image'
import "../index.css";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { createTheme } from "@mui/material";
import { useMemo, forwardRef } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { fullscreen } from '../utils';

import Description from "./Description.jsx";
import Gallery from "./Gallery.jsx";
import Drop from "./Drop.jsx";
import useGraphQLObjktQueries from '../hooks/useGraphQLObjktQueries';

import { useQuery } from '@apollo/client';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { randomBytes } = require('crypto');
let salt = randomBytes(32).toString('hex');
let salt1 = randomBytes(32).toString('hex');

const DEV = false;
const URL_DEV = "http://localhost:3001/";
const URL_PROD = "https://path-of-art2.herokuapp.com/";

const URL = DEV ? URL_DEV : URL_PROD;


const steps = [
    'Uploading Metadata to IPFS',
    'Waiting for wallet permissions',
    'Minted successfully',
];
export default function GenerativeDisabled(props) {
    let { name, artist, id, published, price, images, description, reserved, start_drop, total, token_offset, wallet, Tezos, MARKETPLACE, FA2, date, address: address1, wh_ratio } = props;

    const { COLLECTION, ATTRIBUTE_COUNT, ONSALE } = useGraphQLObjktQueries(total, token_offset, null, name);

    const { loading: loadingG, error, data } = useQuery(COLLECTION);

    const [address, setAddress] = useState();

    const [locked, setLocked] = useState(true);
    /*  useEffect(() => {
         const interval = setInterval(() => {
             if (address === undefined) {
             } else {
                 axios.get(URL + 'locked?id=' + id)
                     .then(res => {
                         if (res.data.locked === true) {
                             setLocked(true);
                             console.log(res.data);
 
                         }
                         if (res.data.locked === false) {
                             console.log(res.data);
 
                             clearInterval(interval);
                             setLocked(false);
                         }
                     }).catch(err => {
                         console.log(err);
                         setLocked(true);
                     });
             }
         }, 60000);
         if (address === undefined) {
         } else {
             axios.get(URL + 'locked?id=' + id)
                 .then(res => {
                     if (res.data.locked === true) {
                         setLocked(true);
                         console.log(res.data);
 
                     }
                     if (res.data.locked === false) {
                         console.log(res.data);
 
                         clearInterval(interval);
                         setLocked(false);
                     }
                 }).catch(err => {
                     console.log(err);
                     setLocked(true);
                 });
         }
         return () => clearInterval(interval);
     }, [address]); */

    // mint operation loading window
    const [open, setOpen] = useState(false);
    //open info when the operation is confirmed
    const [open2, setOpen2] = useState(false);
    //open info when operation is successful
    const [open3, setOpen3] = useState(false);
    // open errror when not enought tezos
    const [open4, setOpen4] = useState(false);
    // open errror when server busy
    const [open5, setOpen5] = useState(false);

    // when snackbar is closed
    const handleCloseSnackBars = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen3(false);
        setOpen2(false);
        setOpen4(false);
        setOpen5(false);

    };


    const handleClose = () => {
        setOpen(false);

    };
    const handleToggle = () => {
        setOpen(!open);
    };





    const [minted, setMinted] = useState(0);


    const sketch = useRef();
    const [step, setStep] = useState(0);


    const [height, setHeight] = useState(window.innerHeight * 0.70);
    const [width, setWidth] = useState(height / wh_ratio);

    const [hours, setHours] = useState(undefined);
    const [minutes, setMinutes] = useState(undefined);

    const [hash, setHash] = useState();
    const [chaos, setChaos] = useState(5);


    /*     useEffect(() => {
            // get request using axios with async/await passing address as param
            if (address === undefined) {
                setDisabledMint(true);
            } else {
                axios.get(URL + 'getOrbs?address=' + address)
                    .then(res => {
                        console.log(res.data);
                        setHash(res.data.lastToken[0]);
                        setHash1(res.data.lastToken[1]);
    
                        setChaos(res.data.orbs[0]);
                        setChrome(res.data.orbs[1]);
    
                        setHours(res.data.timeLeft.split(':')[0]);
                        setMinutes(res.data.timeLeft.split(':')[1]);
                        setDisabledMint(false);
                        setDisabled(false);
                    }).catch(err => {
                        console.log(err);
                        setDisabledMint(true);
                        setChaos(0);
                        setChrome(0);
    
                    });
            }
        }, [address]); */

    const [pattern, setPattern] = useState(5);


    const randomHash = () => {
        if (pattern >= 1) {
            setDisabled(true);
            salt = randomBytes(32).toString('hex');
            setHash(salt);

            updateOrbs(salt, hash1, chaos, 7);
            setPattern(pattern - 1);
            localStorage.setItem("pattern", pattern - 1);
            localStorage.setItem("hash", salt);
        }

    }

    const randomAll = () => {
        if (chaos >= 1) {
            setDisabled(true);
            salt = randomBytes(32).toString('hex');
            salt1 = randomBytes(32).toString('hex');

            setHash(salt);
            setHash1(salt1);


            updateOrbs(salt, hash1, chaos, 7);
            setChaos(chaos - 1);
            localStorage.setItem("chaos", chaos - 1);
            localStorage.setItem("hash", salt);
            localStorage.setItem("hash1", salt1);


        }

    }



    const [hash1, setHash1] = useState();
    const [chrome, setChrome] = useState(5);


    const randomHash1 = () => {
        if (chrome >= 1) {
            setDisabled(true);
            salt1 = randomBytes(32).toString('hex');
            setHash1(salt1);

            updateOrbs(hash, salt1, chaos, chrome - 1);
            setChrome(chrome - 1);

            localStorage.setItem("hash1", salt1);
            localStorage.setItem("chrome", chrome - 1);

        }

    }



    const updateOrbs = (hash, hash1, chaos, chrome) => {
        // post request to server passing address and hash as params
        axios.post(URL + 'updateOrbs', {
            address: address,
            lastToken: hash,
            lastToken1: hash1,
            chaos: chaos,
            chrome: chrome,
        }).then(res => {
            setDisabled(false);
            console.log(res.data);
        }).catch(err => {
            console.log(err);
        });
    };



    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [imageNfts, setImageNfts] = useState([]);







    useEffect(() => {
        const callback = (event) => {
            if (event.storageArea !== localStorage) return;
            if (event.key === 'hash') {
                // Do something with event.newValue
                console.log(event.newValue)
                setHash(event.newValue);
            }
            if (event.key === 'hash1') {
                // Do something with event.newValue
                console.log(event.newValue)
                setHash1(event.newValue);
            }
            if (event.key === 'chaos') {
                // Do something with event.newValue
                console.log(event.newValue)
                if (event.newValue >= 0) {
                    setChaos(event.newValue);
                }
            }
            if (event.key === 'chrome') {
                // Do something with event.newValue
                console.log(event.newValue)
                if (event.newValue >= 0) {
                    setChrome(event.newValue);
                }
            }

        }
        window.addEventListener('storage', callback);
        return () => {
            window.removeEventListener('storage', callback);
        }

    }, []);


    const [attributes, setAttributes] = useState(undefined);

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data.id === "CVERSO_getFeatures") {
                console.log(event.data.data);
                setAttributes(event.data.data)
            }
        });
        return () => {
            window.removeEventListener("CVERSO_getFeatures", (event) => {
                console.log(event.detail);
            });
        }

    }, []);


    let [disabledMint, setDisabledMint] = useState(true);

    useEffect(() => {
        if (address && !locked) {
            setDisabledMint(false);
        }
        if (address === undefined) {
            setDisabledMint(true);

        }
        if (locked) {
            setDisabledMint(true);
        }

    }, [address, locked])

    /* 
        async function upload() {
            try {
                //sketch.current.contentWindow.postMessage("CVERSO_getFeatures", '*');
                // check wallet balance
                setLoading(true);
                setDisabled(true);
                const balance = await Tezos.tz.getBalance(address);
                setLoading(false);
                setDisabled(false);
    
                console.log(balance.toNumber());
                console.log(price * Math.pow(10, 6));
                if (balance.toNumber() < price * Math.pow(10, 6)) {
                    setOpen4(true);
                    return;
                }
                setStep(0);
                setChaos(0);
                setChrome(0);
                updateOrbs(hash, hash1, 0, 0);
                handleToggle();
    
                console.log(attributes);
    
                const data = new FormData();
                const canvas = sketch.current.contentWindow.document.getElementsByTagName("canvas")[0];
                const blob = await new Promise(resolve => canvas.toBlob(resolve));
    
                data.append("image", blob);
                data.append("address", address);
                data.append("hash", hash);
                data.append("hash1", hash1);
                data.append("attributes", JSON.stringify(attributes));
    
                const response = await fetch(URL + "mint", {
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: data
                });
    
                if (response) {
                    return
                    setStep(1);
                    console.log(response);
                    const data = await response.json();
                    console.log(data);
    
                    if (
                        data.status === true &&
                        data.msg.metadataHash &&
                        data.msg.imageHash &&
                        data.msg.resizedHash
                    ) {
                        // saves NFT on-chain
                        const contract = await Tezos.wallet.at(MARKETPLACE);
                        console.log(contract)
                        const op = await contract.methodsObject
                            .mint_token({ collection_id: 4, metadata: char2Bytes("ipfs://" + data.msg.metadataHash) })
                            .send({ amount: price });
                        console.log("Op hash:", op);
                        //mint successfully sent to blockchain 
                        setStep(2);
                        setOpen3(true);
                        setDisabledMint(true);
                        handleClose();
                        setAttributes(undefined);
    
                        salt = randomBytes(32).toString('hex');
                        salt1 = randomBytes(32).toString('hex');
    
                        updateOrbs(salt, salt1, 7, 5);
                        setDisabled(true);
    
                        // 
    
                        await getUserNfts();
                        let confirmation = await op.confirmation(5);
                        salt = randomBytes(32).toString('hex');
    
                        //unlock mintin
                        setDisabledMint(false);
                        setOpen2(true);
                        setChaos(7);
                        setChrome(5);
    
                        setHash(salt);
                        setHash1(salt1);
    
    
                        // refreshes storage
                        await getUserNfts();
                    } else {
                        throw "No IPFS hash";
                    }
                } else {
                    throw "No response";
                }
            } catch (error) {
                console.log(error);
                setStep(0);
                setOpen5(true);
                await getUserNfts();
    
            } finally {
                handleClose();
                setStep(0);
            }
    
        }
     */

    const getUserNfts = async (coll) => {
        let nfts = [];
        const token_metadata1 = await axios.get("https://api.tzkt.io/v1/tokens?contract=" + FA2 + `&offset=${token_offset}&limit=${total}`);
        setMinted(token_metadata1.data.length)

        if (token_metadata1.data.length > 0) {
            nfts = token_metadata1.data.map((nft, id) => {
                if (coll.token[id] !== undefined) {
                    return {
                        token: (token_offset + id),
                        name: coll.token[id].name,
                        thumbnail: coll.token[id].thumbnail_uri?.split("ipfs://")[1],
                        generator: coll.token[id].artifact_uri?.split("ipfs://")[1],
                        image: coll.token[id].display_uri?.split("ipfs://")[1],
                        attributes: coll.token[id].attributes,

                    }
                }
                else {
                    if (nft.metadata !== undefined) {
                        return {
                            token: (token_offset + id),
                            name: nft.metadata?.name,
                            thumbnail: nft.metadata?.thumbnailUri?.split("ipfs://")[1],
                            generator: nft.metadata?.generatorUri?.split("ipfs://")[1],
                            image: nft.metadata?.displayUri?.split("ipfs://")[1],
                            attributes: nft.metadata?.attributes,

                        }
                    }
                }

            }).filter((nft) => { return nft !== undefined });
            nfts.sort((a, b) => a.rarity < b.rarity);
            setImageNfts(nfts);
            //setRandom(Math.floor(Math.random() * imageNfts.length));



        }
    };




    const [reorderedCollection, setReorderedCollection] = useState({ token: [] });

    useEffect(() => {

        if (data?.token?.length > 0) {
            let coll = {};
            coll.token = [...data.token];
            coll.token.sort((a, b) => parseInt(a.token_id, 10) < parseInt(b.token_id, 10) ? -1 : 1);
            console.log(coll);
            setReorderedCollection(coll);
            getUserNfts(coll)
        }
    }, [data]);



    return (
        <>
            <CssBaseline />
            <Container disableGutters maxWidth="lg" sx={{ mt: 6 }}>

                <div className="collection-main" >
                    <div className="collection-main-canvass" >


                        <Paper style={{ maxWidth: '100%', overflow: 'auto' }} square component="img" src={`/images/${id}/logo.png`} sx={{ display: "inline-flex" }} width={width} height={height} alt="" />

                    </div>



                    <p className="desc-rand-image">The collection is locked</p>


                    <Tooltip title="Click to randomize the artwork">
                        <LoadingButton
                            disabled={!address || !chaos}
                            className="bottoni-interni"
                            onClick={() => randomAll()} >CHAOS {chaos}</LoadingButton>
                    </Tooltip>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <Paper elevation={24}>
                            <Divider />
                            <Stepper sx={{ mt: 5, mb: 5 }} activeStep={step} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Paper>
                    </Backdrop>


                    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={open2} autoHideDuration={6000} onClose={handleCloseSnackBars} >
                        <Alert onClose={handleCloseSnackBars} severity="success" sx={{ width: '100%' }} >
                            Your mint has been confirmed. You can mint again.
                        </Alert>
                    </Snackbar>
                    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={open3} onClose={handleCloseSnackBars} >
                        <Alert onClose={handleCloseSnackBars} severity="success" sx={{ width: '50%' }} >
                            You minted successfully, your artwork will be available <Link underline="none" sx={{ textDecoration: 'none' }} target="_blank" href="https://objkt.com/explore/tokens/1?faContracts=KT1S23ui1PKU5G3V52Ds2NyNnPgxJbZhUY6y&sort=timestamp:desc">HERE</Link>.
                            Please wait for the operation confirmation on your wallet before minting
                            again or you will get an error from the blockchain.
                        </Alert>
                    </Snackbar>
                    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={open4} autoHideDuration={6000} onClose={handleCloseSnackBars} >
                        <Alert onClose={handleCloseSnackBars} severity="error" sx={{ width: '100%' }}  >
                            You don't have {price} tezos in your wallet.
                        </Alert>
                    </Snackbar>
                    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={open5} autoHideDuration={6000} onClose={handleCloseSnackBars} >
                        <Alert onClose={handleCloseSnackBars} severity="error" sx={{ width: '50%' }}  >
                            The servers were too busy to handle your request, please try again in a minute.
                        </Alert>
                    </Snackbar>

                    <Tooltip placement="right-start" title={locked ? "Locked" : "Pressing this button will mint the current artwork"}>
                        <span>
                            <LoadingButton
                                disabled={!address || locked || disabledMint || !hash || !hash1 || !attributes}
                                loading={loading}
                                className="bottoni-interni"
                                onClick={() => chaos.log()} > MINT
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <Stack sx={{ mb: "50px" }} direction="row" justifyContent="space-between" alignItems="center">

                        <Stack direction="row" justifyContent="center"  >
                            <Typography className="score" variant="subtitle1" > {minted} / {total} minted</Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="flex-end">

                            <Tooltip title="Open the artwork in fullscreen">
                                <IconButton disabled={(!address || (!hash && !hash1))} onClick={() => fullscreen()} aria-label="fullscreen">
                                    <FullscreenIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Open the live view in a new tab">
                                <IconButton disabled={!address || (!hash && !hash1)} onClick={() => window.open(`${URL}live/${id}?hash=${hash}&hash1=${hash1}`, "_blank")} aria-label="ipfs"><NorthEastIcon /></IconButton>
                            </Tooltip>

                        </Stack>
                    </Stack>


                </div>

                <Description name={name} artist={artist} description={description} price={price} published={published}
                    reserved={reserved} date={date} />

                {/*collection gallery, requires nfts fetched */}3
                <Gallery imageNfts={imageNfts} offset={token_offset} imagesPerRow={2} />

                {/*collection gallery, requires nfts fetched */}


            </Container >
        </>
    )


}






