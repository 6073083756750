import { Divider, Typography, Grid, Stack } from '@mui/material';


export default function Description({ name, artist, description, published, price, reserved = "Can be reserved from 12/05/2022 at ArteFiera Bologna", date = { hour: 16 } }) {
    return (
        <div className="collection-description" >
            <Divider className="collection-divider-norarities" />
            <Typography className="titolo-opera-interno" variant="h3" >{name}</Typography>
            <Typography className="autore" variant="body1" sx={{ fontWeight: 500 }}>by {artist}</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={8} >
                    <Stack direction="column" alignItems="left" spacing={2}>

                        <Typography className="descrizione-interna" variant="subtitle2" sx={{ whiteSpace: 'pre-line', textAlign: 'left' }}>{description}</Typography>
                        <Typography>{"\n\n\n\n"}</Typography>

                    </Stack>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
                    <Stack direction="column" alignItems="left" spacing={2}>

                        <Typography sx={{ fontWeight: 600 }} variant="body2">Release online the {published} at {date.hour}:00 GMT
                        </Typography>
                        <Typography sx={{ fontWeight: 600 }} variant="body2">
                            {reserved}
                        </Typography>
                        <Typography sx={{ fontWeight: 600 }} variant="body2">Price: {price} ꜩ</Typography>
                    </Stack>
                </Grid>

            </Grid>
            <Divider className="collection-divider" />

        </div>

    )

}

/* 
              
                    <div className="collection-description">
                        <Divider className="collection-divider-norarities" />


                        <Stack direction="column" alignItems="center" spacing={2}>
                            <Typography className="titolo-opera-interno" variant="h3" >{name}</Typography>
                            <Typography sx={{ fontWeight: 500 }} className="autore" variant="body1" >by {artist}</Typography>
                            <Typography className="descrizione-interna" variant="subtitle2" style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>{description}</Typography>
                            <Typography>{"\n\n\n"}</Typography>

                            <Typography sx={{ fontWeight: 600, textAlign: 'center' }} variant="body2">Will be available online from {published} 6PM GMT+2</Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: 'center' }} variant="body2">First 50 pieces are reserved for visitor of ArteFiera Bologna  </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: 'center' }} variant="body2">Price: {price} ꜩ</Typography>
                        </Stack>


                        <Divider className="collection-divider" />
                    </div> */