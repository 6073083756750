import { Typography, Stack, Button, LinearProgress, IconButton, CardActionArea, CardMedia, Card } from '@mui/material';
import "../../index.css";
import { useState, useEffect } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { normalise } from "../../utils";
import { Link } from 'react-router-dom';
import axios from "axios";


export default function HomeDisplayCard({ drops, FA2 }) {
    const [collection, setCollection] = useState(0);

    let leftButton = () => { if (collection === 0) { setCollection(drops.length - 1); return } setCollection(collection - 1); }
    let rightButton = () => { if (collection === drops.length - 1) { setCollection(0); return } setCollection(collection + 1); }

    // 
    useEffect(() => {
        const MILLISECOND_CARD_SHOWN = 2000;

        const interval = setInterval(() => {
            rightButton()
        }, MILLISECOND_CARD_SHOWN);
        return () => clearInterval(interval);
    }, [collection])


    /*     useEffect(() => {
            const getUserNfts = async () => {
                const token_metadata1 = await axios.get("https://api.tzkt.io/v1/tokens?contract=" + FA2 + `&offset=${drops[4].token_offset}&limit=${drops[4].total}`);
                setMinted(token_metadata1.data.length);
            }
            getUserNfts();
        }, []) */

    return (
        <>
            <Card  >
                <CardActionArea component={Link} to={drops[collection].url} >

                    <CardMedia component="img" height="240" src={`/images/${collection}/logo.png`} alt="" />

                    {<LinearProgress variant="determinate" value={normalise(drops[collection].minted, drops[collection].total)} />}

                    <Typography variant="h5" className='titolo-opera-card-highlight'>{drops[collection].name}</Typography>

                    <Typography variant="body2" className="nome-artista-card-highlight">by {drops[collection].artist}</Typography>

                    <Button variant='outlined' className="button-card">Explore</Button>

                </CardActionArea>
            </Card>

            <Stack direction="row" justifyContent="space-between">

                <IconButton onClick={leftButton} className="button-card-left"><KeyboardArrowLeftIcon /></IconButton>

                <Typography variant='caption' className='paginazione'>{collection + 1}/{drops.length}</Typography>

                <IconButton onClick={rightButton} className="button-card-right"><KeyboardArrowRightIcon /></IconButton>

            </Stack>

        </>
    )

}