import { Grid, Typography, Link, Stack, Paper, Container, Button, ImageList, ImageListItem, Divider, Box, IconButton, CircularProgress, Tooltip, Backdrop, Stepper, Step, StepLabel, Snackbar, Skeleton, Switch, FormControlLabel, FormGroup, SnackbarContent, TablePagination, Pagination, ToggleButtonGroup, ToggleButton, styled } from "@mui/material";

import { useState, useEffect, useRef } from 'react';
import GenerativeFrame from "./GenerativeFrame.js";
import { CssBaseline, ThemeProvider } from "@mui/material";

import MuiAlert from '@mui/material/Alert';
import { char2Bytes } from "@taquito/utils";
import axios from "axios";
import Image from 'material-ui-image'
import "../index.css";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { createTheme } from "@mui/material";
import { useMemo, forwardRef } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { fullscreen } from '../utils';

import Description from "./Description.jsx";
import Gallery from "./Gallery.jsx";
import Drop from "./Drop.jsx";

import useGraphQLObjktQueries from '../hooks/useGraphQLObjktQueries';
import { useQuery } from '@apollo/client';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const { randomBytes } = require('crypto');
let salt = randomBytes(32).toString('hex');
let salt1 = randomBytes(32).toString('hex');


const DEV = false;
const URL_DEV = "http://localhost:3001/";
const URL_PROD = "https://path-of-art2.herokuapp.com/";

const URL = DEV ? URL_DEV : URL_PROD;


const useGetUser = ({ address }) => {

}

export default function Generative({ name, artist, id, published, price, images, description, locked: defaultLocked,
    reserved, start_drop, total, token_offset, wallet, Tezos, MARKETPLACE, FA2, date, address, wh_ratio }) {

    const sketch = useRef();

    const { COLLECTION, ATTRIBUTE_COUNT, ONSALE } = useGraphQLObjktQueries(total, token_offset, address, name);
    const { loading: loadingG, error, data } = useQuery(COLLECTION);

    //const [locked, setLocked] = useState(false);


    const [locked, setLocked] = useState(true);
    const MILLISECONDS_INTERVAL = 60000

    useEffect(() => {
        if (address === undefined) return;
        if (locked === false) return;
        const isThisCollectionLocked = async (id) => {
            try {
                const res = await axios.get(`${URL}locked?id=${id}`)
                if (res.data.locked === false) {
                    setLocked(false)
                    clearInterval(interval);

                } else if (res.data.locked === true) {
                    setLocked(true);
                }

            } catch (error) {
                console.error(error)
                setLocked(true);
            }
        }

        isThisCollectionLocked(id)
        const interval = setInterval(() => isThisCollectionLocked(id), MILLISECONDS_INTERVAL)


        return () => interval && clearInterval(interval);
    }, [address, id, locked]);


    const [height, setHeight] = useState(window.innerHeight * 0.8);
    const [width, setWidth] = useState(height * 9 / 16);

    /*    const [width, setWidth] = useState(1150 / 2);
   
       const [height, setHeight] = useState(2048 / 2); */



    // mint operation loading window
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);

    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const [minted, setMinted] = useState(0);

    const [step, setStep] = useState(0);




    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);

    const [hash, setHash] = useState(undefined);
    const [hash1, setHash1] = useState(undefined);

    const [chaos, setChaos] = useState(0);
    const [pattern, setPattern] = useState(0);
    const [palette, setPalette] = useState(0);


    useEffect(() => {
        // get request using axios with async/await passing address as param
        if (address === undefined) {
            setDisabledMint(true);
        } else {
            axios.get(URL + 'getOrbs?address=' + address)
                .then(res => {
                    console.log(res.data);
                    setHash(res.data.lastToken[0]);
                    setHash1(res.data.lastToken[1]);

                    /*  setChaos(res.data.orbs[0]);
                     setPattern(res.data.orbs[1]);
                     setPalette(res.data.orbs[2]); */
                    setChaos(10);
                    setPattern(10);
                    setPalette(10);

                    setHours(res.data.timeLeft.split(':')[0]);
                    setMinutes(res.data.timeLeft.split(':')[1]);
                    setDisabledMint(true);
                    setDisabled(true);
                }).catch(err => {
                    console.log(err);
                    setDisabledMint(true);
                    setChaos(0);



                });
        }
    }, [address]);

    const updateOrbs = (hash, hash1, chaos, palette, pattern) => {
        // post request to server passing address and hash as params
        setDisabledMint(true);
        axios.post(URL + 'updateOrbs', {
            address: address,
            lastToken: [hash, hash1],
            chaos: chaos,
            pattern: pattern,
            palette: palette
        }).then(res => {
            setDisabled(false);
            console.log(res.data);
        }).catch(err => {
            console.log(err);
        });
    };



    /*     const [save, setSave] = useState(0);
        async function saveHash() {
            try {
    
    
                setStep(0);
                setChaos(0);
                updateOrbs(hash, 0);
                handleToggle();
    
                const data = new FormData();
                const canvas = sketch.current.contentWindow.document.getElementsByTagName("canvas")[0];
    
                const blob = await new Promise(resolve => canvas.toBlob(resolve));
    
                data.append("image", image);
                data.append("address", address);
                data.append("hash", hash);
                data.append("id", id);
    
                data.append("attributes", JSON.stringify(attributes));
    
                const response = await fetch(URL + "mint", {
                    timeout: 300000,
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: data
                });
    
                if (response) {
    
                    setStep(1);
                    console.log(response);
                    const data = await response.json();
                    console.log(data);
    
    
                }
            } catch (error) {
                console.log(error);
                setOpen5(true);
    
            } finally {
                setStep(0);
                handleClose();
    
            }
    
    
        }
    
     */

    const randomAll = () => {
        if (chaos >= 1) {
            setDisabled(true);

            salt = randomBytes(32).toString('hex');
            setHash(salt);
            salt1 = randomBytes(32).toString('hex');
            setHash1(salt1);

            updateOrbs(salt, salt1, chaos - 1, palette, pattern);
            setChaos(chaos - 1);


            //localStorage.setItem("chaos", chaos - 1);
            localStorage.setItem("hash", salt);
            localStorage.setItem("hash1", salt1);


        }

    }

    const randomPalette = () => {
        if (palette >= 1) {
            setDisabled(true);

            salt = randomBytes(32).toString('hex');
            setHash(salt);

            updateOrbs(salt, hash1, chaos, palette - 1, pattern);
            setPalette(palette - 1)

            localStorage.setItem("hash", salt);
        }
    }

    const randomPattern = () => {
        if (pattern >= 1) {
            setDisabled(true);

            salt1 = randomBytes(32).toString('hex');
            setHash1(salt1);

            updateOrbs(hash, salt1, chaos, palette, pattern - 1);
            setPattern(pattern - 1)
            localStorage.setItem("hash1", salt1);
        }
    }



    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [imageNfts, setImageNfts] = useState([]);

    /* 
        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                sketch.current && sketch.current.contentWindow.location.reload();
    
            }
            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
    
    
     */



    /* useEffect(() => {
        const callback = (event) => {
            if (event.storageArea !== localStorage) return;
            if (event.key === 'hash') {
                // Do something with event.newValue
                console.log(event.newValue)
                setHash(event.newValue);
            }
            if (event.key === 'hash1') {
                // Do something with event.newValue
                console.log(event.newValue)
                setHash1(event.newValue);
            }
            if (event.key === 'chaos') {
                // Do something with event.newValue
                console.log(event.newValue)
                if (event.newValue >= 0) {
                    setChaos(event.newValue);
                }
            }
            if (event.key === 'chrome') {
                // Do something with event.newValue
                console.log(event.newValue)
                if (event.newValue >= 0) {
                    setChrome(event.newValue);
                }
            }
            if (event.key === 'pattern') {
                // Do something with event.newValue
                console.log(event.newValue)
                if (event.newValue >= 0) {
                    setPattern(event.newValue);
                }
            }
            if (event.key === 'disabledMint') {
                // Do something with event.newValue
                console.log(event.newValue)
 
                setDisabledMint(event.newValue);
 
            }
 
        }
        window.addEventListener('storage', callback);
        return () => {
            window.removeEventListener('storage', callback);
        }
 
    }, []); */

    const [image, setImage] = useState(undefined);
    const [attributes, setAttributes] = useState(undefined);
    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        window.addEventListener("message", async (event) => {
            try {
                if (event.data.id === "CVERSO_getFeatures") {
                    const canvas = sketch.current.contentWindow.document.getElementsByTagName("canvas")[0];
                    const blob = await new Promise(resolve => canvas.toBlob(resolve));
                    setImage(blob);
                    setAttributes(event.data.data)
                    setDisabledMint(false);
                    setRendered(true);
                    setDisabled(false)
                }
            } catch (error) {
                setDisabledMint(true)
                setRendered(false);
                setDisabled(true)
            }

        });


        return () => {
            window.removeEventListener("CVERSO_getFeatures", (event) => {
                console.log(event.detail);
            });
        }

    }, []);


    let [disabledMint, setDisabledMint] = useState(true);

    useEffect(() => {
        if (address && !locked) {
            setDisabledMint(false);
        }
        if (address === undefined) {
            setDisabledMint(true);

        }
        if (locked) {
            setDisabledMint(true);
        }

    }, [address, locked])

    const [handleCloseSnackBars, openMintConfirmed, setOpenMintConfirmed, openMintSuccess, setOpenMintSuccess, openInsufficentTezos, setOpenInsufficentTezos, openBusyServer, setOpenBusyServer] = useSnackbars()

    async function upload() {
        try {
            // check wallet balance
            //sketch.current.contentWindow.postMessage("CVERSO_getFeatures", '*');

            setLoading(true);
            setDisabled(true);
            const balance = await Tezos.tz.getBalance(address);
            setLoading(false);
            setDisabled(false);

            console.log(balance.toNumber());
            console.log(price * Math.pow(10, 6));
            if (balance.toNumber() < price * Math.pow(10, 6)) {
                setOpenInsufficentTezos(true);
                return;
            }
            let oldChaos = chaos;
            setStep(0);
            setChaos(0);
            setPalette(0);
            setPattern(0);

            updateOrbs(salt, salt1, 0, 0, 0);

            handleToggle();

            const data = new FormData();
            /*             const canvas = sketch.current.contentWindow.document.getElementsByTagName("canvas")[0];
                        const blob = await new Promise(resolve => canvas.toBlob(resolve));
             */


            data.append("image", image);
            data.append("address", address);
            data.append("hash", hash);
            data.append("hash1", hash1);

            data.append("id", id);

            data.append("attributes", JSON.stringify(attributes));

            const response = await fetch(URL + "mint", {
                timeout: 300000,
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
                body: data
            });

            if (response) {

                setStep(1);
                console.log(response);
                const data = await response.json();
                console.log(data);

                if (
                    data.status === true &&
                    data.msg.metadataHash
                ) {
                    // saves NFT on-chain
                    const contract = await Tezos.wallet.at(MARKETPLACE);
                    console.log(contract)
                    const op = await contract.methodsObject
                        .mint_token({ collection_id: id, metadata: char2Bytes("ipfs://" + data.msg.metadataHash) })
                        .send({ amount: price });
                    console.log("Op hash:", op);
                    //mint successfully sent to blockchain 
                    setStep(2);
                    setOpenMintSuccess(true);
                    setDisabledMint(true);
                    handleClose();

                    salt = randomBytes(32).toString('hex');
                    salt1 = randomBytes(32).toString('hex');

                    updateOrbs(salt, salt1, 20, 0, 0);

                    setDisabled(true);


                    let confirmation = await op.confirmation(5);

                    //unlock mintin
                    setDisabledMint(false);
                    setOpenMintConfirmed(true);
                    setChaos(20);
                    setPalette(0);
                    setPattern(0);

                    setHash(salt);
                    setHash1(salt1);



                } else {
                    throw "No IPFS hash";
                }
            } else {
                throw "No response";
            }
        } catch (error) {
            console.log(error);
            setOpenBusyServer(true);

        } finally {
            setStep(0);
            handleClose();

        }

    }


    const getUserNfts = async (coll) => {
        let nfts = [];
        const token_metadata1 = await axios.get("https://api.tzkt.io/v1/tokens?contract=" + FA2 + `&offset=${token_offset}&limit=${total}`);
        setMinted(token_metadata1.data.length)

        if (token_metadata1.data.length > 0) {
            nfts = token_metadata1.data.map((nft, id) => {
                if (coll.token[id] !== undefined) {
                    return {
                        token: (token_offset + id),
                        name: coll.token[id].name,
                        thumbnail: coll.token[id].thumbnail_uri?.split("ipfs://")[1],
                        generator: coll.token[id].metadata?.split("ipfs://")[1],
                        image: coll.token[id].display_uri?.split("ipfs://")[1],
                        attributes: coll.token[id].attributes,

                    }
                }
                else {
                    if (nft.metadata !== undefined) {
                        return {
                            token: (token_offset + id),
                            name: nft.metadata?.name,
                            thumbnail: nft.metadata?.thumbnailUri?.split("ipfs://")[1],
                            generator: nft.metadata?.generatorUri?.split("ipfs://")[1],
                            image: nft.metadata?.displayUri?.split("ipfs://")[1],
                            attributes: nft.metadata?.attributes,

                        }
                    }
                }

            }).filter((nft) => { return nft !== undefined });
            nfts.sort((a, b) => a.rarity < b.rarity);
            setImageNfts(nfts);
            //setRandom(Math.floor(Math.random() * imageNfts.length));



        }
    };


    const [reorderedCollection, setReorderedCollection] = useState({ token: [] });

    useEffect(() => {

        if (data?.token?.length > 0) {
            let coll = {};
            coll.token = [...data.token];
            coll.token.sort((a, b) => parseInt(a.token_id, 10) < parseInt(b.token_id, 10) ? -1 : 1);
            console.log(coll);
            setReorderedCollection(coll);
            getUserNfts(coll)
        }
    }, [data]);


    return (
        <>
            <CssBaseline />
            <Container disableGutters maxWidth="lg" sx={{ mt: 6 }}>
                <div className="collection-main" >

                    <GenerativeCanvas id={id} sketch={sketch} hash={hash} hash1={hash1} height={height} width={width} address={address} />
                    <MessageBelowCanvas address={address} locked={locked} chaos={chaos} hours={hours} minutes={minutes} />

                    <ButtonsCanvas address={address} chaos={chaos} pattern={pattern} palette={palette} randomPattern={randomPattern} randomPalette={randomPalette} randomAll={randomAll} locked={locked} disabledMint={disabledMint}
                        rendered={rendered} hash={hash} loading={loading} upload={upload} />

                    <CanvasControls address={address} hash={hash} hash1={hash1} id={id} minted={minted} rendered={rendered} total={total} />

                    <MintingDescription />

                    <UploadingMetadata open={open} step={step} />


                    <SnackBars handleCloseSnackBars={handleCloseSnackBars} price={price} openMintConfirmed={openMintConfirmed} openMintSuccess={openMintSuccess} openInsufficentTezos={openInsufficentTezos} openBusyServer={openBusyServer} />
                </div>

                <Description name={name} artist={artist} description={description} price={price} published={published} reserved={reserved} date={date} />
                <Gallery imageNfts={imageNfts} offset={token_offset} imagesPerRow={3} />
                {/* <GenerativeGallery setMinted={setMinted} */}
            </Container >
        </>
    )


}



const MintButton = () => {

}

const CanvasButton = () => {

}

const ButtonsCanvas = ({ address, chaos, palette, pattern, randomPalette, randomPattern, randomAll, locked, disabledMint, rendered, hash, loading, upload }) => {
    return (
        <>
            <LoadingButton
                disabled={!address || !chaos}
                className="bottoni-interni"
                onClick={() => randomAll()} >CHAOS {chaos}</LoadingButton>

            <LoadingButton
                disabled={!address || !pattern}
                className="bottoni-interni"
                onClick={() => randomPattern()} >palette {pattern}</LoadingButton>

            <LoadingButton
                disabled={!address || !palette}
                className="bottoni-interni"
                onClick={() => randomPalette()} >shape {palette}</LoadingButton>
            {/* 
                    <LoadingButton
                        //disabled={!address || !save || disabledMint || !rendered || !hash || !attributes}
                        disabled={!address || !save || !hash || disabledMint || !rendered}

                        className="bottoni-interni"
                        onClick={() => saveHash()} >SAVE {save}</LoadingButton>
 */}

            <Tooltip placement="right-start" title={locked ? "Locked" : "Pressing this button will mint the current artwork"}>
                <span>
                    <LoadingButton

                        disabled={!address || locked || disabledMint || !rendered || !hash}
                        loading={loading}
                        className="bottoni-interni"
                        onClick={() => upload()} > MINT
                    </LoadingButton>
                </span>
            </Tooltip>
        </>
    )
}

const CanvasControls = ({ minted, total, address, id, hash, hash1, rendered }) => {
    return (
        <Stack sx={{ mb: "50px" }} direction="row" justifyContent="space-between" alignItems="center">

            <Stack direction="row" justifyContent="center"  >
                <Typography className="score" variant="subtitle1" > {minted} / {total} minted</Typography>
            </Stack>

            <Stack direction="row" justifyContent="flex-end">

                <Tooltip title="Open the artwork in fullscreen">
                    <IconButton disabled={false} onClick={() => fullscreen()} aria-label="fullscreen">
                        <FullscreenIcon /></IconButton>
                </Tooltip>
                <Tooltip title="Open the live view in a new tab">
                    <IconButton disabled={!address || (!hash) || (!hash1)} onClick={() => window.open(`${URL}live/${id}?hash=${hash}&hash1=${hash1}`, "_blank")} aria-label="ipfs"><NorthEastIcon /></IconButton>
                </Tooltip>

            </Stack>
        </Stack>)
}

const MintingDescription = () => {
    return (
        <Container maxWidth="md">
            <p className="desc-rand-image-expain">
                {/*                 
 */}                If you press CHAOS button, you CANNOT go back to the previous output.<br />
                PALETTE button let you control the colors<br />
                SHAPE button let you control the geometry <br />

                You will be able to mint when the collection is unlocked <br />
                If possible resync your wallet before beginning  <br />
                Use CHROME browser for better performance <br />
                {/*  For each CHAOS you must decide to control either the palette or the shape <br />
                By pressing CHAOS you can start from scratch again and have back more possibilities <br />
                Take times to explore until you find something that is pleasing to you<br />
          
 */}

            </p>
        </Container>
    )
}

const GenerativeCanvas = ({ sketch, address, hash, hash1, height, width, id }) => {
    return (
        <div className="collection-main-canvass" >
            {(address && hash && hash1) ? <CanvasFromGenerativeScript id={id} sketch={sketch} hash={hash} hash1={hash1} height={height} width={width} />
                : <CanvasFromDefaultImage height={height} width={width} id={id} />}
        </div>
    )
}

const CanvasFromDefaultImage = ({ id, width, height }) => {
    return <Paper style={{ maxWidth: '100%', overflow: 'auto' }} component="img" src={`/images/${id}/logo.png`} sx={{ display: "inline-flex" }} width={width} height={height} alt="" />
}

const CanvasFromGenerativeScript = ({ sketch, hash, hash1, height, width, id }) => {
    /*  <Paper square elevation={6} style={{ margin: "auto", height: height, width: width }}>
        <GenerativeFrame className="opera-iframe" id={id} sketch={sketch} hash={hash} hash1={hash1} height={height} width={width} />
    </Paper> */

    return (<Paper square elevation={6} style={{ margin: "auto", height: height, width: width, backgroundColor: "black" }}>
        <GenerativeFrame className="opera-iframe" id={id} sketch={sketch} hash={hash} hash1={hash1} height={height} width={width} />
    </Paper>)
}

const MessageBelowCanvas = ({ address, locked, chaos, hours = 0, minutes = 0 }) => {
    const message = !address ? "You need to be logged in to interact with the artwork" :
        chaos === 0 ? `You will get more iterations after a confirmed mint or in ${hours} hours and ${minutes} min` :
            (locked ? "The collection is locked" : "Please read the minting instructions below");

    return (<p className="desc-rand-image">{message}</p>)
}


const UploadingMetadata = ({ step: activeStep, open: isOpen }) => {
    const steps = [
        'In queue / uploading metadata to IPFS',
        'Waiting for wallet permissions',
        'Minted successfully',
    ];

    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen}>
            <Paper elevation={24}>
                <Divider />
                <Stepper sx={{ mt: 5, mb: 5 }} activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Paper>
        </Backdrop>
    )
}




const useSnackbars = () => {

    //open info when the operation is confirmed
    const [openMintConfirmed, setOpenMintConfirmed] = useState(false);
    //open info when operation is successful
    const [openMintSuccess, setOpenMintSuccess] = useState(false);
    // open errror when not enought tezos
    const [openInsufficentTezos, setOpenInsufficentTezos] = useState(false);
    // open errror when server busy
    const [openBusyServer, setOpenBusyServer] = useState(false);

    // prevent closing by clicking on the page
    const handleCloseSnackBars = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMintConfirmed(false);
        setOpenMintSuccess(false);
        setOpenInsufficentTezos(false);
        setOpenBusyServer(false);

    };

    return [handleCloseSnackBars, openMintConfirmed, setOpenMintConfirmed, openMintSuccess, setOpenMintSuccess, openInsufficentTezos, setOpenInsufficentTezos, openBusyServer, setOpenBusyServer]
}

const SnackBars = ({ handleCloseSnackBars, price, openMintConfirmed, openMintSuccess, openInsufficentTezos, openBusyServer, autoHideDuration = 6000, anchorOrigin = { vertical: "bottom", horizontal: "right" } }) => {
    return (<>

        <Snackbar anchorOrigin={anchorOrigin} open={openMintConfirmed} autoHideDuration={autoHideDuration} onClose={handleCloseSnackBars} >
            <Alert onClose={handleCloseSnackBars} severity="success" sx={{ width: '100%' }} >
                Your mint has been confirmed. You can mint again.
            </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={anchorOrigin} open={openMintSuccess} onClose={handleCloseSnackBars} >
            <Alert onClose={handleCloseSnackBars} severity="success" sx={{ width: '50%' }} >
                You minted successfully, your artwork will be available <Link underline="none" sx={{ textDecoration: 'none' }} target="_blank" href="https://objkt.com/explore/tokens/1?faContracts=KT1S23ui1PKU5G3V52Ds2NyNnPgxJbZhUY6y&sort=timestamp:desc">HERE</Link>.
                Please wait for the operation confirmation on your wallet before minting
                again or you will get an error from the blockchain.
            </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={anchorOrigin} open={openInsufficentTezos} autoHideDuration={autoHideDuration} onClose={handleCloseSnackBars} >
            <Alert onClose={handleCloseSnackBars} severity="error" sx={{ width: '100%' }}  >
                You don't have {price} tezos in your wallet.
            </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={anchorOrigin} open={openBusyServer} autoHideDuration={autoHideDuration} onClose={handleCloseSnackBars} >
            <Alert onClose={handleCloseSnackBars} severity="error" sx={{ width: '50%' }}  >
                The servers were too busy to handle your request, please try again in a minute.
            </Alert>
        </Snackbar>
    </>
    )
}
