import { useState } from 'react';
import { Paper, Skeleton } from "@mui/material";

export default function CanvassRandomMinted({ imageNfts, width, height, random, loading, setLoading }) {
    return (

        <>
            <Paper component={"img"}
                elevation={6}
                id="image-drop"
                square
                width={width} height={height}
                src={`https://cverso.mypinata.cloud/ipfs/${imageNfts[random].image}`}
                alt=""
                onLoad={() => setLoading(false)}

                style={{ display: !loading ? "inline-flex" : "none", maxWidth: '100%', overflow: "auto" }} />
            {loading && <Skeleton
                width={width} height={height}
                variant='rectangular' animation='wave'
                style={{ display: "inline-flex" }} />}

        </>


    )
};