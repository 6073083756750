import { Grid, Paper, Typography, Box } from "@mui/material"

export default function Attributes({ random, attributes, attributes_values: totals, total }) {
    let rarity = 1;

    return (
        <>
            <Paper>
                <Typography variant="h4">Attributes</Typography>
                <Grid container>
                    {attributes.map((attribute, index) => {

                        rarity = rarity * (totals[attribute.name][attribute.value] / total);
                        return (
                            <Grid item xs={12} sm={6} md={4} key={attribute.name}>
                                <Box>
                                    <Typography variant="h6">{attribute.name}</Typography>
                                    <Typography variant="subtitle1">{attribute.value}</Typography>
                                    <Typography variant="subtitle2">{(totals[attribute.name][attribute.value] / total * 100).toFixed(1)}%</Typography>

                                </Box>
                            </Grid>
                        )
                    }
                    )}
                </Grid>
            </Paper>
            <Paper>
                <Typography variant="h4">Statistics</Typography>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4} >
                        <Box>
                            <Typography variant="h6">Rarest</Typography>
                            <Typography variant="subtitle2">{random + 1}   </Typography>

                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={6} md={4} >
                        <Box>
                            <Typography variant="h6">Rarity</Typography>
                            <Typography variant="subtitle2">{(rarity * 100).toFixed(2)}%</Typography>

                        </Box>

                    </Grid>


                    <Grid item xs={12} sm={6} md={4} >
                        <Box>
                            <Typography variant="h6">Multiplier</Typography>
                            <Typography variant="subtitle2">{(0.0287 / rarity).toFixed(0)} </Typography>

                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >
                        <Box>
                            <Typography variant="h6">Est. Value</Typography>
                            <Typography variant="subtitle2">{(Math.sqrt(0.0287 / rarity * 25) + 24).toFixed(0)}-{(0.0287 / rarity * 25).toFixed(0)} ꜩ</Typography>

                        </Box>

                    </Grid>


                </Grid>
            </Paper>
        </>
    )
}