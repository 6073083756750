
import { useState } from 'react';
import "./navigator.css";
import "../../index.css";
import { NavLink, Link, useLocation } from "react-router-dom";
import { walletPreview } from '../../utils';

import MarketplaceBackdrop from '../MarketplaceBackdrop';

import { AppBar, Toolbar, Typography, Button, IconButton, Menu, Avatar, MenuItem, Container, Divider, Grid, Stack } from "@mui/material";

export default function Navigator({ wallet, address, setAddress }) {
    let location = useLocation();
    const [anchorElUser, setAnchorElUser] = useState(null);



    const connect = async () => {
        try {
            const permissions = await wallet.client.requestPermissions();
            setAddress(permissions.address);
            handleCloseUserMenu()
        } catch (error) {
            console.log(error);
            setAddress(undefined);
            handleCloseUserMenu()
        }

    }
    const disconnect = async () => {
        try {
            await wallet.client.clearActiveAccount();
            setAddress(undefined);
            handleCloseUserMenu();
        } catch (error) {
            console.log(error);
            setAddress(undefined);
            handleCloseUserMenu()

        }

    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };




    return (
        <>
            <AppBar className='nav-bar' position="sticky" sx={{ background: 'white' }}>
                <Container maxWidth="lg">
                    <Toolbar>
                        <Grid container justifycontent="center">
                            <Grid className='nav-logo-container' item xs={12} md={2} >
                                <Link to="/">
                                    <img className='nav-logo'
                                        alt=""
                                        src={"/images/logo.jpeg"}
                                    />
                                </Link>
                            </Grid >
                            <Grid className='nav-fill' item></Grid>
                            <div className="menu-navbar">
                                <Grid item>
                                    <Stack direction="row">

                                        <Button className={location.pathname === "/" ? 'nav-button-selected' : 'nav-button'} onClick={handleCloseUserMenu} component={NavLink} to="/">HOME</Button>
                                        <Button className={location.pathname === "/artist" ? 'nav-button-selected' : 'nav-button'} onClick={handleCloseUserMenu} component={NavLink} to="artist">ARTISTS</Button>
                                        {/*                                         <Button className={location.pathname === "/faq" ? 'nav-button-selected' : 'nav-button'} onClick={handleCloseUserMenu} component={NavLink} to="faq">FAQ</Button>

 */}                                        <Button className={location.pathname === "/events" ? 'nav-button-selected' : 'nav-button'} onClick={handleCloseUserMenu} component={NavLink} to="events">EVENTS</Button>
                                        {/*     <Button className={location.pathname === "/explore" ? 'nav-button-selected' : 'nav-button'} onClick={handleCloseUserMenu} component={NavLink} to="explore">STATS</Button>

 */}

                                        <Divider orientation="vertical" flexItem />
                                        <div className="div-sync" >
                                            {!address ? <Button className='nav-sync nav-button' onClick={connect}>SYNC</Button> :
                                                <>
                                                    <Typography className="nav-address" variant="caption" color="primary" >{walletPreview(address)}</Typography>
                                                    <IconButton className="nav-userLogo" color="primary" onClick={handleOpenUserMenu} >
                                                        <Avatar alt={walletPreview(address)} src={"https://services.tzkt.io/v1/avatars2/" + address} />
                                                    </IconButton>

                                                    <Menu className='nav-userMenu'
                                                        id="menu-appbar"
                                                        anchorEl={anchorElUser}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        keepMounted
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        open={Boolean(anchorElUser)}
                                                        onClose={handleCloseUserMenu}
                                                    >
                                                        <MenuItem component={NavLink} to="collected">
                                                            collected
                                                        </MenuItem>
                                                        <MenuItem onClick={disconnect}>

                                                            unsync
                                                        </MenuItem>


                                                    </Menu>
                                                </>}
                                        </div>
                                    </Stack>
                                </Grid>
                            </div>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar >
        </>
    )

}