import { Backdrop, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import { Link } from 'react-router-dom';



export default function MarketplaceBackdrop({ drops, open: isOpen, handleClose: handleClose }) {


    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen} onClick={handleClose}>
            <Container maxWidth="md" >
                <Paper elevation={24}>
                    <Box sx={{ mt: 5 }}>
                        <div className='titolo-evidenziato'><h1>Select a collection</h1></div>

                        <Grid container >
                            {drops && drops.map((drop, index) => {
                                if (index >= 15) return
                                if (drop.type === 1) return
                                let url = index >= 14 ? "" : drop.url + "/marketplace"

                                return (
                                    <Grid key={index} item xs={12} sm={6} md={4} >
                                        <Box sx={{ m: 5 }} >
                                            <Card   >
                                                <CardActionArea component={Link} to={url}>


                                                    <CardMedia
                                                        component="img"
                                                        height="50"
                                                        width="50"
                                                        image={`/images/${drop.id}/logoThumbnail.png`}
                                                        alt={drop.name}
                                                    />
                                                    <CardContent>
                                                        <Typography variant="h6" component="div" sx={{ textOverflow: 'ellipsis' }}>
                                                            {drop.name}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {drop.artist}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>

                </Paper>
            </Container>
        </Backdrop >
    )
}


/* const Card = ({ drop }) => {
    return (
        <CardContent>
            {drop.name}
        </CardContent>

    )
} */