
import { Container, Grid, Link as MUILink, Box } from '@mui/material';
import HomeDropCard from './HomeDropCard';
import HomeDisplayCard from './HomeDisplayCard';
import HomeDropCardDisabled from './HomeDropCardDisabled'
import HomeOffersCard from './HomeOffersCard';

import Image from 'material-ui-image'
import { useQuery } from "@apollo/client";
import useGraphQLObjktQueries from "../../hooks/useGraphQLObjktQueries";
import { LazyLoadComponent } from 'react-lazy-load-image-component';





export default function Home(props) {
    const drops = props.data.drops

    const CURRENT_DROP = 15
    const isUpcomingDrop = (id) => { return id >= CURRENT_DROP }
    const isPastDrop = (id) => { return !isUpcomingDrop(id) }

    return (
        <>
            {/* banner */}
            <div className='home-header'>
                {/*  <div ><h1 >Next curated collection <br></br>will be released the 9/9/22</h1>
                </div> */}
            </div>

            <Container >
                {/* midsection */}
                <Grid container className='home-highlight' alignItems="center" >
                    {/*left side description of website*/}
                    <Grid item xs={12} sm={12} md={7} >
                        <Container>
                            <h1>Curated Generative Art Exhibitions <br></br>with <MUILink underline="none" >#CleanNFTs</MUILink> on the <MUILink target="_blank" href="https://tezos.com/" underline="none" >
                                Tezos Blockchain</MUILink>.</h1>
                            <h3>A bridge between Generative and Contemporary Art.</h3>
                            <p className='testo-home'></p>

                            <p className='testo-home'>Collect Generative Art NFTs exhibited in important art fairs and gallery spaces. </p>
                            <p className='testo-home'>NFTs from our Exhibition Drops are sold directly by the creator. You can collect them here before they sell out, or purchase them from others on the secondary market on <MUILink sx={{ fontWeight: 500 }} target="_blank" href="https://objkt.com/" underline="none" >objkt.com</MUILink> </p>
                            <p className='testo-home'>To learn more about our physical events follow us on <MUILink sx={{ fontWeight: 500 }} target="_blank" href="https://www.twitter.com/CortesiVerso" underline="none" >twitter</MUILink></p>
                        </Container>
                    </Grid>
                    {/*spacing*/}
                    <Grid item xs={0} sm={0} md={1}></Grid>

                    {/*right side cards */}
                    <Grid item xs={12} sm={6} md={4}>
                        <HomeDisplayCard drops={drops.slice(0, CURRENT_DROP)} FA2={props.FA2} />
                        {/*  <HomeOffersCard address={props.address} /> */}

                    </Grid>
                </Grid>

                {/* upcoming drops section */}
                {/* <div className='titolo-evidenziato'><h1>Current Exhibition Drops</h1></div> */}
                <Grid container spacing={1} justifyContent="flex-start" alignItems="stretch"  >
                    {/* 
                    <Grid item xs={12} sm={4} key={drops[12].name} style={{ display: 'flex', flexGrow: '1' }}>
                        <HomeDropCard style={{ display: 'flex' }} {...drops[12]} FA2={props.FA2}  >{drops[12]["name"]}</HomeDropCard>
                    </Grid> */}


                    {drops.map(drop => {
                        if (isPastDrop(drop.id)) return null

                        return (
                            <Grid item xs={12} sm={4} key={drop.name} style={{ display: 'flex', flexGrow: '1' }}>
                                <HomeDropCard style={{ display: 'flex' }} {...drop} FA2={props.FA2}  >{drop["name"]}</HomeDropCard>
                            </Grid>
                        )
                    })}

                </Grid>

                <Box sx={{ m: 10 }}></Box>
                {/* past drops section */}
                <div className='titolo-evidenziato'><h1>Past Exhibition Drops</h1></div>
                <Grid container spacing={1} justifyContent="flex-start" alignItems="stretch"   >
                    {drops.map(drop => {
                        if (isUpcomingDrop(drop.id)) return null

                        return (
                            <Grid item xs={12} sm={4} key={drop.name} style={{ display: 'flex', flexGrow: '1' }}>
                                <LazyLoadComponent>
                                    <HomeDropCard style={{ display: 'flex' }} {...drop} FA2={props.FA2}  >{drop["name"]}</HomeDropCard>
                                </LazyLoadComponent>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container >
        </>
    )

}