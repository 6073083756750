import Frame from "react-frame-component";
import { Stack } from '@mui/material';
import Iframe from 'react-iframe';
import { useState, useEffect } from "react";
import ejs from 'ejs';


//import v7 from "../views/entangled7.ejs";
//import v7 from "../views/entangled7.ejs";
/* import v1 from "../views/index2.ejs";
import v2 from "../views/index5.ejs"; */


const GenerativeFrame = ({ sketch, height, width, hash, hash1, id }) => {
  const [text, setText] = useState();
  const [data, setData] = useState();

  //load the template as string
  useEffect(() => {
    async function fetchData() {
      fetch(`/views/index${id}.ejs`)
        .then((response) => response.text())
        .then((textContent) => {
          setText(textContent);
        });
    }
    fetchData();
  }, [id]);

  //render the template with the random hash
  useEffect(() => {
    if (text && hash && hash1) {
      let data = ejs.render(text, { hash: hash, hash1: hash1 });
      setData(data);
    }
  }, [text, hash, hash1]);

  return (
    <>
      {data && <iframe srcDoc={data}
        ref={sketch}
        width={width}
        height={height}
        frameBorder="0"
        title="canvas"
        id="myFrame"
        className="opera-iframe"
        display="initial"
        position="relative"
        allowvr="yes"
        allowFullScreen=""
        allow="xr-spatial-tracking"
        scrolling="no"

      />}
    </>
  );

}

export default GenerativeFrame;
