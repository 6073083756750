import { useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { Button, Container, Grid, Typography, Box, Paper } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

let API_endpoint = "https://api.better-call.dev/v1/account/mainnet/KT1FVqzFUCD8LZAEeoNG3YsW1hkNnHNHMsKt/token_balances?size=50&hide_empty=true&contract_address=KT1S23ui1PKU5G3V52Ds2NyNnPgxJbZhUY6y"

function Collected({ address }) {
    const [imageNfts, setImageNfts] = useState([]);
    const address1 = "tz1fFPXxTbquqtiToE9xfk8aXFE5tLJbfJ8X";
    useEffect(() => {
        //axios get callback
        const API = `https://api.tzkt.io/v1/tokens/balances?account=${address}&size=250&balance=1&contract=KT1S23ui1PKU5G3V52Ds2NyNnPgxJbZhUY6y`

        address && axios.get(API).then(res => {
            console.log(res.data);
            let nfts = [];

            if (res.data.length > 0) {
                console.log("we are loading images")
                nfts = res.data.map((nft, id) => {
                    if (nft.balance !== "1" || nft.token.contract.address !== "KT1S23ui1PKU5G3V52Ds2NyNnPgxJbZhUY6y") { return undefined }
                    return {
                        name: nft.token?.metadata?.name,
                        thumbnail: nft.token?.metadata?.thumbnailUri?.split("ipfs://")[1],
                        image: nft.token?.metadata?.displayUri?.split("ipfs://")[1],
                        token_id: nft.token?.tokenId,
                    }
                }).filter((nft) => { return nft !== undefined });
                console.log(nfts);
                setImageNfts(nfts)
            }
        });
    }, [address]);


    return (
        <>
            {/* banner */}
            <div className='home-header'>
                <div ><h1> Collected</h1>
                </div>
            </div>

            <Container maxWidth="lg" >

                <div className="collection-gallery">
                    <Grid container justify="center"
                        className="collection-gallery-imagelist-collected"  >
                        {/*item gallery*/}
                        {(imageNfts?.length > 0) ? imageNfts.map((item, index) => {
                            return (
                                <Grid item key={index} xs={12} sm={6} md={4} >
                                    <div className="collection-gallery-image"  >

                                        <Box className="frame-collected"

                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <LazyLoadImage
                                                src={`https://cverso.mypinata.cloud/ipfs/${item.thumbnail}`}
                                                alt=""
                                                loading="lazy"
                                            />
                                        </Box>
                                        <Typography className="titolo-minted" noWrap variant="body2">{item.name}</Typography>
                                        {/*                                         <Typography className="nome-artista-card-highlight" variant="body2">artist name</Typography>
 */}
                                        <Button className="bottoni-interni-minted" target="_blank" href={`https://objkt.com/asset/c-verso/${item.token_id}`}>
                                            objkt
                                        </Button>
                                        <Button className="bottoni-interni-minted" target="_blank" href={`https://cverso.mypinata.cloud/ipfs/${item.image}`}>
                                            image
                                        </Button>
                                    </div>
                                </Grid>

                            )
                        })
                            : <Grid item xs={12} sx={{ mt: 15, mb: 60 }}> <Typography variant="body" >Yow don't have any artwork yet... </Typography></Grid>
                        }
                    </Grid>

                </div>




            </Container>
        </>
    )


}



export default Collected;