import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, LinearProgress } from '@mui/material';
import { Link } from "react-router-dom";
import Countdown from 'react-countdown';
import axios from "axios";
import "../../index.css";

import { normalise } from "../../utils";



export default function HomeDropCard({ url, id, minted, total, token_offset, name, artist, image, description, start, end, start_drop, date, FA2 }) {
    const [mint, setMint] = useState(minted);
    const [realdate, setRealdate] = useState();

    useEffect(() => {
        const getUserNfts = async () => {
            if (id === "14") {
                const token_metadata1 = await axios.get("https://api.tzkt.io/v1/tokens?contract=" + FA2 + `&offset=${token_offset}&limit=${total}`);
                setMint(token_metadata1.data.length);
            }
        }
        getUserNfts();

        let newDate = new Date(Date.UTC(2022, date.month - 1, date.day, date.hour)).getTime();
        setRealdate(newDate)
    }, [date])

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, days, completed }) => {


        if (completed) {
            // Render a completed state
            return <Typography className="countdown" variant='body2' >   {mint} / {total} minted </Typography >;

        } else {
            // Render a countdown
            return <Typography className="countdown" variant='body2' > {"Starts in "}
                {days} days {hours} hrs {minutes} mins </Typography >;
        }
    };
    const disabled = parseInt(id) >= 16

    const page_url = disabled ? "" : url
    return (
        <>        {realdate && <Card sx={{ display: 'flex', flexGrow: '1' }} >
            <CardActionArea component={Link} to={page_url} >
                <CardMedia component="img" height="240" image={'/images/' + id + '/logo.png'} alt="" />
                <LinearProgress variant="determinate" value={normalise(mint, total)} />
                <CardContent >
                    <Typography className="titolo-opera" variant="h3" >{name}</Typography>
                    <Typography className="nome-artista" variant="h5" >by {artist}</Typography>
                    <Countdown date={realdate} renderer={renderer} />
                    <Button disabled={disabled} variant='outlined' className="button-card-piccole ">Explore</Button>

                </CardContent>

            </CardActionArea>



        </Card>}
        </>

    );
}



