import { Grid, Container } from "@mui/material";
import "../../index.css";

import ArtistCard from "./ArtistCard";

const bios = [
    "Stefano Contiero is an Italian-Dominican self-taught artist born in Bassano del Grappa (IT).\n During childhood, he developed a strong interest in technology and design, which first led to a career in tech, and later combined into generative art.\nHis parents' often-clashing cultures and personalities gifted him a fragmented sense of belonging, which plays a big part in his inspiration and is key to understanding himself.\n   After developing his craft in solitude for many years, he became known for Frammenti (Art Blocks Curated Series 3) and Rinascita (Art Blocks Playground)",
    "Ben Kovach is a visual artist from Vermont, USA who uses computer code as a medium, with randomness as a core element. Ben's work is exploratory and diverse - he is interested in pushing the boundaries of the digital aesthetic and building natural feeling artwork with what is traditionally considered a cold and lifeless tool",
    "ippsketch is a computational artist from Austin, Texas. With a background in engineering and simulation, he uses computer code and stochastic processes to create digital art. His work tends to focus on simple geometric forms, clean lines, and subtle textures.",
    "ALT+ESC is the pseudonym of the NFT artist based in California, USA. His urban landscapes defined imagined universe that also exist “in between”, in alternative realities, rising questions about our real world in continuous evolution.",
    "Anna Lucia is an abstract artist and an engineer born in the Netherrlands that currently lives in Cairo, Egypt. The artist uses computer programming as her favourite medium; the result of the compositional instructions is a vibrant geometrical composition characterized by bright colours. Anna Lucia minted her first NFT in 2021 and was part of the Tezos exhibition at Art Basel Miami later this year.",
    "Julien Gachadoat has been exploring generative drawing for many years, creating unique art with algorithms. He works with the emergence of abstract form, combining monochrome, geometric shapes, playing with repetition and using random operations to generate an element of surprise.",
    "Anna Carreras (Barcelona, 1979) is a creative coder and digital artist interested in experimentation on interactive communication focusing her work on the use of generative algorithms, creative code and interactive technology as a means of communication and an experience generator.",
    "She's been gradually moving more data art and generative art over the years. Letting her get even more creative when the constraints of clearly explaining the data can be let go. Focusing instead on the visual appeal, the feeling the works evokes, and how it might still allude subtly to the trends in the underlying data (if present). Her works can often be recognized through the use of vibrant color palettes and a playful feel.",
    "Jean-Jacques Duclaux, known as Eko33, is a Swiss-French generative artist who has been at the forefront of digital arts since 1999 when he started writing code to create generative artworks and installations. He made his debut using a Commodore SX-64 and floppy disks. Since then, his work has been exhibited in Museums, International art fairs, and biennales. His work is focused on nonfigurative, generative art while using custom algorithms and innovative technology.",
    "Emily Xie is a generative artist and engineer living in NYC. She works with algorithms to create lifelike patterns, textures, materials, and forms which are often encoded with references to her own culture and femininity.",
    `Former oil painter, longtime programmer, working at the convergence of figurative and generative arts. Bewitched by density, lush plants and trees. Plotter art enthusiast.`,
    "Ben Kovach is a visual artist from Vermont, USA who uses computer code as a medium, with randomness as a core element. Ben's work is exploratory and diverse - he is interested in pushing the boundaries of the digital aesthetic and building natural feeling artwork with what is traditionally considered a cold and lifeless tool",
    "Rich is a generative artist living in the South Island of New Zealand. He focuses on creating artwork that feels organic and often eschews traditional digital lines. Rich’s early generative work was purely physical and much of his work is crafted with prints or plots in mind.",
    "Michael Connolly is an artist and creative director based in the UK.\n\nSince graduating in 2007 he has used code and software to produce work across a broad range of disciplines, from design and motion, to live visuals and installation.\n\nHis generative art practice is essentially a machine based approach to concrete art (as defined by Theo van Doesburg in 1930). An objective exploration of line, form and colour using randomness within defined parameters.\n\nHis first on chain generative project ‘Elementals’ was longlisted for the 2021 Lumen Digital Art Prize.",
    "ippsketch is a computational artist from Austin, Texas. With a background in engineering and simulation, he uses computer code and stochastic processes to create digital art. His work tends to focus on simple geometric forms, clean lines, and subtle textures.",

];

const twitters = [
    "https://twitter.com/stefan_contiero",
    "https://twitter.com/bendotk",
    "https://twitter.com/ippsketch",
    "https://twitter.com/alt_escapism",
    "https://twitter.com/annaluciacodes",
    "https://twitter.com/v3ga",
    "https://twitter.com/carreras_anna",
    "https://twitter.com/NadiehBremer",
    "https://twitter.com/Eko3316",
    "https://twitter.com/emilyxxie",
    "https://twitter.com/zancan",
    "https://twitter.com/bendotk",
    "https://twitter.com/rich__poole",
    "https://twitter.com/xiiixiii",
    "https://twitter.com/ippsketch",


];

const websites = [
    "https://stefanocontiero.com",
    "https://bendotk.com/",
    "https://ippsketch.com/",
    "https://www.fxhash.xyz/u/ALT%2BESC",
    "https://y.at/🎲🖼💾🐉",
    "https://www.v3ga.net/",
    "https://www.annacarreras.com/",
    "https://www.visualcinnamon.com/art/",
    "https://www.eko33.com/",
    "https://www.emilyxie.design/",
    "https://zancan.art/",
    "https://bendotk.com/",
    "https://www.richpoole.art/",
    "https://michaelconnolly.work/",
    "https://ippsketch.com/",


];




export default function Artist({ drops }) {
    return (
        <>
            <div className='home-header'>
                <h1> Artists</h1>
            </div>
            <Container sx={{ mb: 10, mt: 10 }}>
                <Grid container rowSpacing={10} columnSpacing={3}>
                    {drops.map((drop, index) => {
                        let url = index >= 15 ? "" : drop.url

                        return (
                            <Grid key={index} item xs={12} sm={6} md={4} >
                                <ArtistCard name={drop.artist}
                                    address={drop.address}
                                    image={`/images/${drop.id}/logo.png`}
                                    url={url}
                                    website={websites[index]}
                                    bio={bios[index]}
                                    twitter={twitters[index]}
                                    avatar={`/images/${drop.id}/avatar.jpg`} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </>

    )
}