import React, { useState, useEffect } from "react";
import { Box, Button, ButtonGroup, Container, Divider, Grid, IconButton, ImageList, ImageListItem, LinearProgress, Link, Paper, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { createTheme } from "@mui/material";
import { CssBaseline, ThemeProvider } from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import PublicIcon from '@mui/icons-material/Public';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Description from "./Description";
import "../index.css"

/* let marketplace = "KT1BJaN9oY2SuUzwACxSegGJynkrRbQCEEfX"

//old contract adress marketplace
let API_endpoint = "https://api.better-call.dev/v1/account/mainnet/KT1K6yyk917uAyhMUToDQK68P1oK1EtUnfRs/token_balances?size=50&hide_empty=true" */

//axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";


export default function Drop({ name, date, artist, id, published, price, images, reserved, description, locked, minted, start_drop, total, token_offset, wallet, Tezos, primary_color, address, FA2, MARKETPLACE }) {

    let API_endpoint = `https://api.tzkt.io/v1/tokens/balances?account=${MARKETPLACE}&size=50&balance=1`

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: "light",
                    primary: {
                        main: "#005266",
                        //main: "#c8ff00",
                        //main: '#738a00'
                    },
                    secondary: {
                        //main: '#b0003f'
                        main: "#f50057",
                    },
                    background: {
                        default: primary_color,
                        /*  default: "#ECF2ED", */
                        paper: "#ECF2ED",
                    },
                },
                typography: {
                    fontFamily: [
                        'Montserrat',
                        'sans-serif',
                    ].join(','),

                },

            }),
        [primary_color],
    );

    let [image, setImage] = useState(0);
    let [imageURL, setImageURL] = useState("images/" + id + "/" + images[0].url);
    let [available, setAvailable] = useState([]);
    let [sold, setSold] = useState([]);
    let [disabledMint, setDisabledMint] = useState(true);


    /* Function to open fullscreen mode */
    function openFullscreen() {
        /* Get the element you want displayed in fullscreen */
        var elem = document.getElementById("image-drop");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    useEffect(() => {
        // get which token are available to be transferred
        async function fetchData() {
            try {
                const token_metadata = await axios.get(API_endpoint);
                console.log(token_metadata)
                let token_available = token_metadata.data
                //.balance.filter(e => e.balance === "1" && e.contract === FA2);
                console.log(token_available)

                let token_available_offset = token_available.map(e => e.token.tokenId - token_offset)
                console.log(token_available_offset)
                let token_available_this_collection = token_available_offset.filter(e => e < total && e >= 0)
                let token_sold = [...Array(total).keys()].filter(x => !token_available_this_collection.includes(x));
                if (token_sold.length + token_available_this_collection.length !== total) { throw Error }

                setSold(token_sold)
                setAvailable(token_available_this_collection);

                if (token_available_this_collection.length === 0) {
                    setImage(0);
                    setImageURL("images/" + id + "/" + images[0].url)
                }
                else {
                    setImage(token_available_this_collection.sort()[0])
                    setImageURL("images/" + id + "/" + images[token_available_this_collection.sort()[0]].url)

                }


            } catch (error) {
                console.log(error)
            }


        }
        fetchData();
    }, [MARKETPLACE]);

    // change photo displayed
    const changePhoto = (i) => {
        setImage(i)
        setImageURL("images/" + id + "/" + images[i].url)
    }

    const mint = async () => {
        try {
            const contract = await Tezos.wallet.at(MARKETPLACE);
            console.log(contract)
            console.log(id);
            console.log(image);

            const op = await contract.methodsObject
                .transfer_token({ collection_id: id, token_id: image })
                .send({ amount: price });
        } catch (error) {
            console.log(error)

        }

    }
    useEffect(() => {
        if (address && !locked) {
            setDisabledMint(false);
        }
        if (address === undefined) {
            setDisabledMint(true);
        }
    }, [address, locked])

    return (
        <>
            <ThemeProvider theme={theme}><CssBaseline></CssBaseline>
                <Container sx={{ mt: 6 }}>
                    {/* main content artwork + commands */}
                    <div className="collection-main">
                        {/*artwork*/}
                        <div className="collection-main-img">
                            <Paper style={{ maxWidth: '100%', overflow: 'auto' }} square id="image-drop" elevation={6} component="img" src={imageURL} alt="" ></Paper>
                            {/*{imageURL && <img id="image-drop" src={imageURL} alt="" />}*/}
                            {/*                             {imageURL && <img id="image-drop" src={imageURL} alt="" />}
 */}                        </div>
                        {/*commands*/}
                        <div>
                            <Grid container justifyContent="center"  >
                                {images.map(((img, index) => {

                                    return (
                                        <Grid item key={index + 1}>
                                            <Button sx={{ mr: 0 }} size="small" className={image === index ? (!available.includes(index) ? 'paginazione-interna-active paginazione-interna-disabled' : 'paginazione-interna-active')
                                                : (!available.includes(index) ? 'paginazione-interna paginazione-interna-disabled' : 'paginazione-interna')}
                                                onClick={() => changePhoto(index)}>{index + 1}</Button>
                                        </Grid>
                                    )
                                }))}
                                {/*                                 <Button className={'paginazione-interna-active paginazione-interna-disabled'} > disabled active </Button>
                                <Button className={'paginazione-interna paginazione-interna-disabled'} > disabled normale </Button> */}
                            </Grid>
                            <Tooltip title={!available.includes(image) ? "This item is not available" : (disabledMint ? (locked ? "This collection is still locked" : "You need to SYNC your wallet to mint") : "Click to mint the selected artwork")}>
                                <div><Button disabled={disabledMint || !available.includes(image)} onClick={() => mint()} className="bottoni-interni " > Mint</Button></div>
                            </Tooltip>
                            {/*                             <Button disabled={true} onClick={() => mint()} className="bottoni-interni bottoni-interni-disabled" > Mint</Button>
 */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" justifyContent="center"  >
                                    <Typography className="score" > {(total - available.length) + " / " + total + "  minted"}</Typography>
                                    {/*                                     <Typography className="score" > {locked ? "Minting begins the " + published : (total - available.length) + " / " + total + " minted"}</Typography>
 */}                                </Stack>
                                <Stack direction="row" justifyContent="flex-end">

                                    <Tooltip title="Open the artwork in fullscreen">
                                        <IconButton onClick={() => openFullscreen()} aria-label="fullscreen">
                                            <FullscreenIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Open the artwork in a new tab">
                                        <IconButton aria-label="ipfs" target="_blank" href={"https://cverso.mypinata.cloud/ipfs/" + images[image].artifact}>
                                            <NorthEastIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Open the metadata">

                                        <IconButton aria-label="ipfs-metadata" target="_blank" href={"https://cverso.mypinata.cloud/ipfs/" + images[image].metadata}>
                                            <PublicIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        </div>
                    </div>
                    {/*collection description */}

                    <Description name={images[image].name} artist={artist} description={description} price={price} published={published} date={date} reserved={reserved} />

                    {/*sold item gallery */}

                    <div className="collection-gallery">
                        {(sold.length > 0) && (<Typography variant="h3" className="titolo-sezione-minted" style={{ fontFamily: "Montserrat, sanserif" }}>Minted </Typography>)}
                        <Grid container className="collection-gallery-imagelist" >
                            {(sold.length > 0) && sold.map((item) => {
                                return (
                                    <Grid item key={item} xs={12} sm={id === "1" ? 6 : 4} md={id === "1" ? 6 : 4}  >
                                        <div className="collection-gallery-image" >
                                            <img elevation={3}

                                                src={id === 0 ? "images/" + id + "/" + images[item].url : "images/" + id + "/thumbnails/" + images[item].url} alt="" />
                                            <Typography noWrap className="titolo-minted" variant="body2">{images[item].name}</Typography>
                                            <Button className="bottoni-interni-minted" component={Link} href={"https://objkt.com/asset/c-verso/" + (parseInt(token_offset) + parseInt(item))} target="_blank">
                                                objkt
                                            </Button>
                                            <Button className="bottoni-interni-minted" target="_blank" href={"https://cverso.mypinata.cloud/ipfs/" + images[item].artifact}>
                                                image
                                            </Button>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </Container>
            </ThemeProvider>
        </>)
}

