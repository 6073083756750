import React, { useState, useEffect } from 'react';
import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { NetworkType } from "@airgap/beacon-sdk";

export const TezosContext = React.createContext([{}, () => { }]);

const TEST = true;
const TESTNET = { url: "https://hangzhounet.api.tez.ie", NetworkType: NetworkType.HANGZHOUNET, }
const MAINNET = { url: "https://mainnet-tezos.giganode.io", NetworkType: NetworkType.MAINNET, }
const CONSTANTS = TEST ? TESTNET : MAINNET;

export const TezosProvider = (props) => {
  const [state, setState] = useState({});


  useEffect(() => {
    const Tezos = new TezosToolkit(CONSTANTS.url);
    console.log("Created a Tezos toolkit with url: " + CONSTANTS.url)
    const wallet = new BeaconWallet({ name: "Path of Art Client", preferredNetwork: CONSTANTS.NetworkType });
    console.log("Created a Beacon Wallet on network: " + CONSTANTS.NetworkType);

    Tezos.setWalletProvider(wallet);


    setState((state) => {
      console.log("Saving Tezos and wallet state");
      return { Tezos: Tezos, wallet: wallet, ...state }
    });

    console.log("Checking if the wallet is already connected to an account");
    wallet.client.getActiveAccount()
      .then((account) => {
        console.log("We are connected to the account : ");
        console.log(account);
        console.log("Saving account state");
        setState((state) => { return { address: account?.address, account: account, ...state } });
      }).catch((err) => {
        console.log("We couldn't retrieve the address");
        console.log(err);
        setState((state) => { return { address: undefined, account: undefined, ...state } });
      });



  }, []);

  return (
    <TezosContext.Provider value={[state, setState]}>
      {props.children}
    </TezosContext.Provider>
  )
}


//connect to a random tezos node through beacon
/*       const RPCs = [
        //"https://mainnet-tezos.giganode.io",
        //"https://mainnet.api.tez.ie",
      ]; */
//const randomRpc = RPCs[Math.floor(RPCs.length * Math.random())];
//check if we are already connected, if so we set address