import { Grid, Typography, Link, Stack, Paper, Container, Button, ImageList, ImageListItem, Divider, Box, IconButton, CircularProgress, Tooltip, Backdrop, Stepper, Step, StepLabel, Snackbar, Skeleton, Switch, FormControlLabel, FormGroup, SnackbarContent, TablePagination, Pagination } from "@mui/material";

import { useState, useEffect } from 'react';
import { CssBaseline } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Image from 'material-ui-image'
import "../index.css";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import Description from "./Description.jsx";
import Gallery from "./Gallery.jsx";
import CanvassRandomMinted from "./CanvassRandomMinted.jsx";
import Attributes from "./Attributes.js";
import Prices from "./Prices.js";
import Iframe from "react-iframe";

import useGraphQLObjktQueries from '../hooks/useGraphQLObjktQueries';

import { useQuery } from '@apollo/client';
import { id2objkt, ipfs2pinata, getTezosAmount } from "../utils";
import { fullscreen } from '../utils';


export default function GenerativeCompleted(props) {
    let navigate = useNavigate();

    let params = useParams();
    let { attributes_values, attributes, interactive, interactive_default = false, highlighted, name, artist, id, date, url, published, price, images, description, reserved = "", imagesPerRow, locked, start_drop, total, token_offset, wallet, Tezos, MARKETPLACE, FA2, address, minted, wh_ratio = 1 } = props;

    const { COLLECTION, ATTRIBUTE_COUNT, ONSALE } = useGraphQLObjktQueries(total, token_offset, address, name);
    const { loading: loadingG, error, data } = useQuery(COLLECTION);
    const { loading: loadingA, error: errorA, data: dataA } = useQuery(ATTRIBUTE_COUNT);
    const { loading: loadingS, error: errorS, data: dataS } = useQuery(ONSALE);

    const [height, setHeight] = useState(window.innerHeight * 0.70);
    const [width, setWidth] = useState(height * wh_ratio);

    const [chaos, setChaos] = useState(99);
    const [checked, setChecked] = useState(interactive_default);

    const handleChange = (event) => {
        setLoading(true);
        setChecked(event.target.checked);
    };

    const [imageNfts, setImageNfts] = useState([]);
    const [random, setRandom] = useState(params.id - 1 || 0);

    const getUserNfts2 = async (coll) => {
        let nfts = [];
        const token_metadata1 = await axios.get("https://api.tzkt.io/v1/tokens?contract=" + FA2 + `&offset=${token_offset}&limit=${total}`);
        if (token_metadata1.data.length > 0) {
            nfts = token_metadata1.data.map((nft, id) => {
                if (nft.metadata !== undefined) {
                    return {
                        token: (token_offset + id),
                        name: nft.metadata?.name,
                        thumbnail: nft.metadata?.thumbnailUri?.split("ipfs://")[1],
                        generator: nft.metadata?.generatorUri?.split("ipfs://")[1],
                        image: nft.metadata?.displayUri?.split("ipfs://")[1],
                        attributes: nft.metadata?.attributes,

                    }
                }

            }).filter((nft) => { return nft !== undefined });
            //nfts.sort((a, b) => a.rarity < b.rarity);
            setImageNfts(nfts);
            //setRandom(Math.floor(Math.random() * imageNfts.length));



        }
    };
    /**
     * Extract the attribute from objkt attribute in a more convenient form
     * "attributes": [
    {
      "__typename": "token_attribute",
      "attribute": {
        "__typename": "attribute",
        "name": "Palette",
        "value": "Nike",
        "id": 320001,
        "attribute_counts": [
          {
            "__typename": "fa2_attribute_count",
            "editions": 26
          }
        ]
      }
    }, => "attributes":[{"name":"Palette","value":"Nike"},{"name":"Terrain","value":"Forest"},{"name":"Constructs","value":"Monuments"},{"name":"Dream","value":"Lucid"},{"name":"Light","value":"Natural"}]
    
     * @param {*} attributes attribute in objkt notation
     * @returns {*} standardAttributeFormat attribute in tzkt notation
     */
    const extractAttributeFromObjktRequest = (attributes, total) => {
        if (attributes === undefined) {
            return undefined
        }
        let standardAttributeFormat = []
        let newAttribute = {}
        let rarity = 1

        attributes.forEach(attribute => {
            newAttribute = {}
            newAttribute.name = attribute.attribute.name
            newAttribute.value = attribute.attribute.value
            newAttribute.count = attribute.attribute.attribute_counts[0].editions
            rarity = rarity * (newAttribute.count * total)
            standardAttributeFormat.push(newAttribute);
        });

        console.log(standardAttributeFormat);


        return [standardAttributeFormat, rarity]
    }

    const getUserNfts = async (coll) => {
        let nfts = [];
        const token_metadata1 = await axios.get("https://api.tzkt.io/v1/tokens?contract=" + FA2 + `&offset=${token_offset}&limit=${total}`);
        if (token_metadata1.data.length > 0) {
            nfts = token_metadata1.data.map((nft, id) => {
                if (coll.token[id] !== undefined) {
                    let [attributes, rarity] = extractAttributeFromObjktRequest(coll.token[id].attributes, total)
                    return {
                        token: (token_offset + id),
                        name: coll.token[id].name,
                        thumbnail: coll.token[id].thumbnail_uri?.split("ipfs://")[1],
                        generator: coll.token[id].artifact_uri?.split("ipfs://")[1],
                        image: coll.token[id].display_uri?.split("ipfs://")[1],
                        attributes: attributes,
                        rarity: rarity


                    }
                }
                else {
                    if (nft.metadata !== undefined) {
                        return {
                            token: (token_offset + id),
                            name: nft.metadata?.name,
                            thumbnail: nft.metadata?.thumbnailUri?.split("ipfs://")[1],
                            generator: nft.metadata?.generatorUri?.split("ipfs://")[1],
                            image: nft.metadata?.displayUri?.split("ipfs://")[1],
                            attributes: nft.metadata?.attributes,

                        }
                    }
                }

            }).filter((nft) => { return nft !== undefined });

            //nfts.sort((a, b) => a.rarity < b.rarity);
            setImageNfts(nfts);
            //setRandom(Math.floor(Math.random() * imageNfts.length));



        }
    };


    const [reorderedCollection, setReorderedCollection] = useState({ token: [] });

    useEffect(() => {

        if (data?.token?.length > 0) {
            let coll = {};
            coll.token = [...data.token];
            coll.token.sort((a, b) => parseInt(a.token_id, 10) < parseInt(b.token_id, 10) ? -1 : 1);
            console.log(coll.token)
            setReorderedCollection(coll);
            (parseInt(id) === 2) ? getUserNfts2(coll) : getUserNfts(coll)
        }
    }, [data]);



    const [loading, setLoading] = useState(true);



    const [onSales, setOnsales] = useState([]);


    useEffect(() => {

        if (dataS?.token?.length > 0) {
            setChaos(dataS.token.length);
            setOnsales([...dataS.token])

        }
    }, [dataS]);


    function randomize() {
        let n = Math.floor(Math.random() * onSales?.length);
        n = onSales.splice(n, 1)[0].token_id - token_offset
        setChaos(chaos - 1);
        setRandom(n);
        changeImage(n)
        setLoading(true);
    }

    function changeImage(id) {
        setLoading(true);
        navigate(`${url}/${id + 1}`);
        setRandom(id);
    }

    /* Function to open fullscreen mode */
    function openFullscreen() {
        if (checked) {
            fullscreen();
            return
        }
        /* Get the element you want displayed in fullscreen */
        var elem = document.getElementById("image-drop");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    return (
        <>
            {/*  <ThemeProvider theme={theme} */}

            <CssBaseline />
            <Container disableGutters maxWidth="lg" sx={{ pt: 7, pb: 6 }}>

                <div className="collection-main" >
                    <Box sx={{ height: height }}>
                        <div className="collection-main-canvass" >

                            {/*   {(loadingIframe && hash) ?
                                    <GenerativeFrame className="opera-iframe" sketch={sketch} height={height} width={width} hash={hash} />
                                    : <img src="/images/2/placeholder.png" sx={{ display: "inline-flex" }} variant="rectangular" width={width} height={height} alt="" />} */}
                            {/* 
                                {imageNfts.length > 0 ?
                                    <img component={LazyLoadImage}
                                        width={width} height={height}
                                        src={`https://cverso.mypinata.cloud/ipfs/${imageNfts[Math.floor(Math.random() * 200)].image}`}
                                        alt=""
                                    />
                                    : <img src="/images/2/placeholder.png" sx={{ display: "inline-flex" }} variant="rectangular" width={width} height={height} alt="" />
                                }

                                 */}
                            {/* <img src="/images/2/placeholder.png" sx={{ display: "inline-flex" }} variant="rectangular" width={width} height={height} alt="" />  */}

                            {attributes ?
                                <Grid container>
                                    <Grid item xs={12} sm={8}>
                                        {!checked ?
                                            (imageNfts.length > 0) && <CanvassRandomMinted
                                                loading={loading} setLoading={setLoading}
                                                imageNfts={imageNfts} height={height} width={width} random={random} />
                                            :
                                            <>
                                                {(imageNfts.length > 0) &&
                                                    <Paper elevation={6} square>
                                                        <Iframe url={`https://cverso.mypinata.cloud/ipfs/${imageNfts[random].generator}`}
                                                            width={width - 5}
                                                            height={height - 5}
                                                            id="canvas"
                                                            title="canvas"
                                                            onLoad={() => setLoading(false)}
                                                            display={loading ? "none" : "inline-flex"}
                                                            frameBorder="0"
                                                            sandbox="allow-scripts allow-downloads allow-same-origin"
                                                            allowvr="yes"
                                                            allowfullscreen="yes"
                                                            scrolling="no"

                                                        /></Paper>}
                                                {loading && <Skeleton
                                                    width={width} height={height}
                                                    variant='rectangular' animation='wave'
                                                    style={{ display: "inline-flex" }} />
                                                }
                                            </>



                                        }


                                    </Grid>

                                    <Grid item xs={0} sm={4} >
                                        {(imageNfts.length > 0) &&

                                            <Box style={{ maxHeight: '70%', overflow: 'auto' }}>
                                                <Typography className="titolo-opera-interno" variant="h3" sx={{ p: 2 }}>{imageNfts[random].name}</Typography>
                                                {imageNfts[random].attributes && <Attributes random={random} total={total} attributes_values={attributes_values} attributes={imageNfts[random].attributes} />}

                                            </Box>
                                        }

                                    </Grid>

                                </Grid>
                                : <div> {!checked ?
                                    (imageNfts.length > 0) && <CanvassRandomMinted
                                        loading={loading} setLoading={setLoading}
                                        imageNfts={imageNfts} height={height} width={width} random={random} />
                                    :
                                    (imageNfts.length > 0) &&

                                    <Paper square elevation={6} style={{ margin: "auto", height: height, width: width }}>

                                        <Iframe url={`https://cverso.mypinata.cloud/ipfs/${imageNfts[random].generator}`}
                                            width={width}
                                            height={height}
                                            title="canvas"
                                            onLoad={() => setLoading(false)}
                                            //display={loading ? "none" : "inline-flex"}
                                            frameBorder="0"
                                            sandbox="allow-scripts allow-downloads allow-same-origin"
                                            allowvr="yes"
                                            allowfullscreen=""
                                            scrolling="no"
                                        />
                                    </Paper>
                                }




                                </div>


                            }


                        </div>
                    </Box>
                    <p className="desc-rand-image">{"Click chaos to get a random artwork for sale"}</p>



                    <Tooltip title="Click to randomize the artwork">
                        <Button
                            disabled={!chaos | loading}
                            className="bottoni-interni"
                            onClick={() => randomize()} >CHAOS {chaos}</Button>
                    </Tooltip>


                    <Tooltip title={"Trade this item on objkt.com "}>
                        <span>
                            <Button
                                disabled={loading}
                                className="bottoni-interni"
                                component={Link} href={imageNfts[random] ? "https://objkt.com/asset/c-verso/" + imageNfts[random].token : ""} target="_blank"
                            > BUY {reorderedCollection?.token[random]?.lowest_ask && getTezosAmount(reorderedCollection.token[random].lowest_ask) + "ꜩ"}
                            </Button>                    </span>

                    </Tooltip>



                    <span>

                        <Button
                            className="bottoni-interni"
                            onClick={() => navigate(`${url}/marketplace`)} >Marketplace</Button>
                    </span>

                    {interactive ?
                        <FormGroup sx={{ ml: 1 }} >
                            <FormControlLabel control={<Switch

                                defaultChecked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                size="small"
                            />} label="Interactive" />

                        </FormGroup> : null}

                    <Stack sx={{ mb: "50px" }} direction="row" justifyContent="space-between" alignItems="center">

                        <Stack direction="row" justifyContent="center"  >
                            <Typography className="score" variant="subtitle1" color="primary"> #{random + 1} of {total} </Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="flex-end">

                            <Tooltip title="Open the artwork in fullscreen">
                                <IconButton disabled={!chaos | loading} onClick={() => openFullscreen()} aria-label="fullscreen">
                                    <FullscreenIcon /></IconButton>
                            </Tooltip>

                            <Tooltip title="Open the artwork in a new tab">
                                <IconButton disabled={!chaos | loading} onClick={() => window.open(imageNfts[random] ? `https://cverso.mypinata.cloud/ipfs/${imageNfts[random].generator}` : "", "_blank")} aria-label="ipfs"><NorthEastIcon /></IconButton>
                            </Tooltip>

                        </Stack>
                    </Stack>


                </div>

                {/*collection description */}
                <Description date={date} name={name} artist={artist} description={description} price={price} published={published}
                    reserved={reserved} />
                {highlighted?.length > 0 &&

                    <div className="collection-gallery">

                        <Typography className="titolo-opera-interno" variant="h3" >Highlighted</Typography>
                        <Grid container className="collection-gallery-imagelist"  >
                            {/*item gallery*/}
                            {(imageNfts?.length > 0 && highlighted?.length > 0) && highlighted.map((item, index) => {
                                return (
                                    <Grid item key={index} xs={12} sm={6} md={12 / imagesPerRow} >
                                        <div className="collection-gallery-image" >
                                            <Paper component={Button} onClick={() => changeImage((item))} >
                                                <img
                                                    src={`https://cverso.mypinata.cloud/ipfs/${imageNfts[item]?.thumbnail}`}
                                                    alt=""
                                                />
                                            </Paper>

                                            <Typography className="titolo-minted" variant="body2">{item + 1} {/* {item.rarity && " , " + (Math.sqrt(0.0287 / item.rarity * 25) + 24) + "ꜩ"} */}</Typography>

                                            <Button className="bottoni-interni-minted" component={Link} href={"https://objkt.com/asset/c-verso/" + imageNfts[item]?.token} target="_blank">
                                                trade {reorderedCollection?.token[item]?.lowest_ask && getTezosAmount(reorderedCollection.token[item].lowest_ask) + "ꜩ"}
                                            </Button>
                                            <Button className="bottoni-interni-minted" target="_blank" href={`https://cverso.mypinata.cloud/ipfs/${imageNfts[item]?.generator}`}>
                                                live
                                            </Button>
                                        </div>
                                    </Grid>

                                )
                            })}
                        </Grid>
                        <Divider className="collection-divider-norarities" sx={{ mt: 5 }} />
                    </div>
                }

                {/*collection gallery, requires nfts fetched */}
                {(imageNfts?.length > 0 && reorderedCollection) && <Gallery changeImage={changeImage} imageNfts={imageNfts} offset={token_offset} imagesPerRow={imagesPerRow} url={url} reorderedCollection={reorderedCollection} />}

            </Container >
        </>
    )


}

