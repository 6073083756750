import React, { useState } from 'react';


import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { NetworkType } from "@airgap/beacon-sdk";
//connect to a random tezos node through beacon
/*       const RPCs = [
        //"https://mainnet-tezos.giganode.io",
        //"https://mainnet.api.tez.ie",
      ]; */
//const randomRpc = RPCs[Math.floor(RPCs.length * Math.random())];
//check if we are already connected, if so we set address
export const APIContext = React.createContext([{}, () => { }]);


const DEV = false;
const URL_DEV = "http://localhost:3001/";
const URL_PROD = "https://path-of-art.herokuapp.com/";


const URL = DEV ? URL_DEV : URL_PROD;


export const APIProvider = (props) => {
  const [state, setState] = useState({
    baseURL: URL
  });

  return (
    <APIContext.Provider value={[state, setState]}>
      {props.children}
    </APIContext.Provider>
  )
}
