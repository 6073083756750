import { gql } from "@apollo/client";

export default function useGraphQLObjktQueries(total = 0, token_offset = 0, address = null, collectionName = null, order_by = "asc") {
  const FA = "KT1S23ui1PKU5G3V52Ds2NyNnPgxJbZhUY6y";

  const ATTRIBUTE_COUNT = gql`
  query AttributeCount {
    fa2_attribute_count(where: {fa_contract: {_eq: ${FA}}}) {
      attribute {
        name
        value
  
      }
      tokens
    }
  }`;

  const RECENTLY_SOLD = gql`
  query RecentlySold($_in: [event_type!] = ["ask_purchase", "dutch_purchase", "accept_offer", "conclude_auction", "accept_offer_floor"]) {
    event(where: {fa_contract: {_eq:  ${FA}}, event_type: {_in: $_in}, _and: {token: {name: {_iregex: "^${collectionName}*"}}, price: {_gt: "0"}}}, order_by: {timestamp: desc}, limit: 50) {
      price
      token {
        artifact_uri
        display_uri
        last_listed
        metadata
        name
        thumbnail_uri
        token_id
      }
    }
  }`;


  const HIGHEST_SOLD = gql`
  query HighestSold($_in: [event_type!] = ["ask_purchase", "dutch_purchase", "accept_offer", "conclude_auction", "accept_offer_floor"]) {
    event(where: {fa_contract: {_eq:  ${FA}}, event_type: {_in: $_in}, _and: {token: {name: {_iregex: "^${collectionName}*"}}, price: {_gt: "0"}}}, order_by: {price: desc}) {
      price
  
    }
  }  `;

  /*   const ONSALE = gql`
    query OnSale {
      listing_sale(where: {token: {fa_contract: {_eq: ${FA}}, _and: {name: {_regex: "^${collectionName}*"}}}}, order_by: {price: ${order_by}}) {
        price
        sender_address
        seller_address
        token {
          artifact_uri
          display_uri
          last_listed
          metadata
          name
          thumbnail_uri
          token_id
        }
      }
    }
    `; */
  const ONSALE = gql`
    query OnSale {
      token(where: {fa_contract: {_eq: ${FA}}, _and: {lowest_ask: {_neq: 0}, _and: {name: {_regex: "^${collectionName}*"}}}}, order_by: {lowest_ask: ${order_by}}) {
        artifact_uri
        display_uri
        last_listed
        metadata
        name
        thumbnail_uri
        token_id
        lowest_ask
      }
    }
    
  `;


  const COLLECTION = gql`
  query Collection {
    token(where: {fa_contract: {_eq: ${FA}}, _and: {name: {_regex: "^${collectionName}*"}}}) {
      display_uri
      metadata
      artifact_uri
      name
      thumbnail_uri
      lowest_ask
      attributes {
        attribute {
          name
          value
          id
          attribute_counts {
            editions
          }
        }
      }
      token_id
    }
  }
  `;


  const LAST_OFFERS = gql`
  query LastOffers {
    obj_offer(where: {fa_contract: {_eq: ${FA}}, _and: {status: {_eq: "active"}}}) {
      id
      token {
        display_uri
        highest_offer
        metadata
        name
        thumbnail_uri
        token_id
      }
    }
  }`;

  const LAST_ASKS = gql`
  query LastAsks {
    obj_ask(where: {fa_contract: {_eq: ${FA}}, _and: {status: {_eq: "active"}}}) {
      token {
        display_uri
        metadata
        name
        thumbnail_uri
        token_id
      }
      price
    }
  }`;


  const COLLECTED = gql`
  query Collected {
    token(where: {fa_contract: {_eq: ${FA}}, _and: {holders: {holder_address: {_eq: ${address}}, _and: {quantity: {_eq: "1"}}}}}) {
      display_uri
      highest_offer
      metadata
      name
      thumbnail_uri
      token_id
    }
  }`;

  const STATISTICS = gql`
  query Statistics {
    fa(where: {name: {_eq: "C-VERSO"}}) {
      volume_total
      volume_24h
      active_listing
      active_auctions
      category
      floor_price
      items
      owners
    }
  }`



  return {
    LAST_OFFERS, COLLECTED, LAST_ASKS, COLLECTION, ONSALE, RECENTLY_SOLD, HIGHEST_SOLD, ATTRIBUTE_COUNT, STATISTICS
  };
}



