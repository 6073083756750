import { Button, Grid, Pagination, Stack, Typography, Link, Paper, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getTezosAmount } from "../utils";

export default function Gallery({ imageNfts, offset, imagesPerRow, changeImage, reorderedCollection = null }) {


    const [page, setPage] = useState(1);
    const [display, setDisplay] = useState(imageNfts.slice(0, 20));

    useEffect(() => {
        handleChange(0, 1)
    }, [imageNfts])


    const handleChange = (event, value) => {
        setPage(value);
        setDisplay(imageNfts.slice(20 * (value - 1), 20 * value));

    };


    if (imageNfts?.length === 0) { return (<></>) }
    return (

        <div className="collection-gallery">
            {(imageNfts?.length > 0) && (
                <>
                    <Typography variant="h3" className="titolo-opera-interno" style={{ fontFamily: "Montserrat, sanserif" }}>Minted </Typography>
                </>)
            }

            <Stack spacing={2} alignItems="center" justifyContent="center">
                <Pagination count={Math.ceil(imageNfts.length / 20)} page={page} onChange={handleChange} />
            </Stack>


            <Grid container className="collection-gallery-imagelist"  >
                {/*item gallery*/}
                {(display && imageNfts?.length > 0) && display.map((item, index) => {

                    return (
                        <Grid item key={index} xs={12} sm={6} md={12 / imagesPerRow} >
                            <div className="collection-gallery-image" >
                                <Paper component={Button} onClick={() => changeImage((index + 20 * (page - 1)))} >
                                    <LazyLoadImage
                                        src={`https://cverso.mypinata.cloud/ipfs/${item.thumbnail}`}
                                        alt=""
                                    />
                                </Paper>

                                <Typography className="titolo-minted" variant="body2">{item.token - offset + 1} {/* {item.rarity && " , " + (Math.sqrt(0.0287 / item.rarity * 25) + 24) + "ꜩ"} */}</Typography>

                                <Button className="bottoni-interni-minted" component={Link} href={"https://objkt.com/asset/c-verso/" + item.token} target="_blank">
                                    trade {reorderedCollection?.token[index + 20 * (page - 1)]?.lowest_ask && getTezosAmount(reorderedCollection.token[index + 20 * (page - 1)].lowest_ask) + "ꜩ"}
                                </Button>
                                <Button className="bottoni-interni-minted" target="_blank" href={`https://cverso.mypinata.cloud/ipfs/${item.generator}`}>
                                    live
                                </Button>
                                <Button className="bottoni-interni-minted" target="_blank" href={`https://cverso.mypinata.cloud/ipfs/${item.image}`}>
                                    image
                                </Button>
                            </div>
                        </Grid>

                    )
                })}
            </Grid>

            <Stack spacing={2} alignItems="center" justifyContent="center">
                <Pagination count={Math.ceil(imageNfts.length / 20)} page={page} onChange={handleChange} />
            </Stack>

        </div >
    )
}