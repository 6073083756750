import { useState } from 'react';
import "../../index.css";

import { Card, CardHeader, CardActionArea, CardMedia, CardContent, CardActions, Collapse, Avatar, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TwitterIcon from '@mui/icons-material/Twitter';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';

import { Link } from 'react-router-dom';


export default function ArtistCard({ name, image, id, url, website, bio, twitter, avatar }) {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ backgroundColor: "background.paper" }}><CardActionArea component={Link} to={url}>
            <CardHeader sx={{ '& .MuiCardHeader-title': { fontSize: 22, fontWeight: 700, color: "primary.main" } }}
                avatar={<Avatar alt={name[0]} sx={{ colorDefault: "primary.main" }} aria-label="recipe" src={avatar} />}
                title={name} />
            <CardMedia component="img" height="194" image={image} alt="" />

            <CardContent>
                {expanded
                    ?
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>
                                {bio}</Typography>
                        </CardContent>
                    </Collapse>
                    :
                    <CardContent>
                        <Typography sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            lineClamp: 6,
                            WebkitLineClamp: 6,
                            WebkitBoxOrient: 'vertical',
                        }} paragraph>
                            {bio}</Typography>
                    </CardContent>}
            </CardContent>
        </CardActionArea>
            <CardActions disableSpacing>


                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>


                <IconButton sx={{ ml: "auto", color: "primary.main" }} aria-label="twitter" target="_blank" href={twitter} >
                    <TwitterIcon />
                </IconButton>

                <IconButton sx={{ color: "primary.main" }} aria-label="website" target="_blank" href={website} >
                    <WebAssetIcon />
                </IconButton>
                {/*                 <IconButton sx={{ color: "primary.main" }} aria-label="drop" target="_blank" href={url} >
                    <ExploreOutlinedIcon />
                </IconButton> */}
            </CardActions>

        </Card>
    );
}


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
