import * as React from 'react';
import { styled, Container, Accordion as MuiAccordion, Typography, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import questions from './questions';
const wallet = {
    "index": 11,
    "title": "HOW DO I OPEN A WALLET TO LOGIN AND BUY NFTs?",
    "content": "In order to interact with C-VERSO you will need a tezos wallet. You can find a list of wallets on the Tezos website. We recommend Temple (browser extension) or Kukai (web). Once you have a wallet and a Tezos address on it, you can synchronize your wallet with C-VERSO by clicking on the sync button on the top right. In order to purchase NFTs on the platform you will need Tezos (cryptocurrency). It can be obtained through various exchange platforms, such as Coinbase or Kraken."
}
export default function FAQ() {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => { setExpanded(isExpanded ? panel : false); };

    return (
        <>
            <div className='home-header'>
                <div ><h1 >Faq</h1>
                </div>
            </div>
            <Container sx={{ pt: 10, pb: 20 }} >
                <Accordion expanded={expanded === `panel${12} `} onChange={handleChange(`panel${12} `)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="primary" />}
                        aria-controls={`panel${12} - content`}
                        id={`panel${12} a - header`}
                    >
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'Montserrat', color: "#015265" }}>{wallet.title}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography sx={{ fontFamily: 'Montserrat', textAlign: 'left' }}>

                            In order to interact with C-VERSO you will need a tezos wallet.
                            You can find a list of wallets on the Tezos website.
                            We recommend  <Link target="_blank" href="https://templewallet.com/" underline="none"  >
                                Temple</Link> (browser extension) or <Link target="_blank" href="https://wallet.kukai.app/" underline="none"  >
                                Kukai</Link> (web). Once you have a wallet and a Tezos address on it,
                            you can synchronize your wallet with C-VERSO by clicking on the sync button on the top right.
                            In order to purchase NFTs on the platform you will need Tezos (cryptocurrency).
                            It can be obtained through various exchange platforms,
                            such as <Link target="_blank" href="https://www.coinbase.com/" underline="none"  >
                                Coinbase</Link> or <Link target="_blank" href="https://www.kraken.com/" underline="none"  >
                                Kraken</Link>.




                        </Typography>
                    </AccordionDetails>
                </Accordion>




                {questions.map((row, index) => {
                    const { title, content } = row;
                    return (
                        <Accordion key={index} expanded={expanded === `panel${index} `} onChange={handleChange(`panel${index} `)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color="primary" />}
                                aria-controls={`panel${index} - content`}
                                id={`panel${index} a - header`}
                            >
                                <Typography sx={{ fontWeight: 'bold', fontFamily: 'Montserrat', color: "#015265" }}>{title}</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography sx={{ fontFamily: 'Montserrat', textAlign: 'left' }}>{content}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <Accordion expanded={expanded === `panel${wallet.index} `} onChange={handleChange(`panel${wallet.index} `)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="primary" />}
                        aria-controls={`panel${wallet.index} - content`}
                        id={`panel${wallet.index} a - header`}
                    >
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'Montserrat', color: "#015265" }}>HOW DO I JOIN AS AN ARTIST?</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography sx={{ fontFamily: 'Montserrat', textAlign: 'left' }}>

                            For now we decided to grow on our own terms with the goal of exhibiting curated show, however we are always happy to meet Artists and see amazing Generative Art.
                            If you desire to join us please follow this link <Link target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdPA9rMfh9OJOiqMPKPwuOAen5lUu1iPQNwS1RVexvV27ux1g/viewform?vc=0&c=0&w=1&flr=0" underline="none"  >
                                Apply Here</Link>.




                        </Typography>
                    </AccordionDetails>
                </Accordion>


            </Container>

        </>
    );
}

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider} `,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));