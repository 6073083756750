import { useState, useEffect, lazy, Suspense, Fragment } from "react";
import { Routes, Route } from "react-router-dom";


// connect to tezos wallet 
import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { NetworkType } from "@airgap/beacon-sdk";


import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import "./App.css";
import axios from "axios";

import useCalculateAttributes from "./hooks/useCalculateAttributes";

import Footer from "./components/Footer";
import Navigator from "./components/navigator/Navigator";

import Home from "./components/home/Home";
import FAQ from "./components/faq/FAQ";
import Artist from "./components/artist/Artist";
import Events from "./components/Events";

import GenerativeCompleted from "./components/GenerativeCompleted";
import Drop from "./components/Drop";
import Collected from "./components/Collected";
import Marketplace from "./components/Marketplace";
import MarketplaceHome from "./components/MarketplaceHome";

//import Drop2 from "./components/Drop2";
//import DropMulti from "./components/DropMulti";
//import Escape from "./components/Escape";
//import GenerativeShow from "./components/GenerativeShow";
//import Generative from "./components/Generative";
//import GenerativeTest from "./components/GenerativeTest";

import Generative from "./components/Generative";

import GenerativeDisabled from "./components/GenerativeDisabled";

const GenerativeShow = lazy(() => import('./components/GenerativeShow'));

const DropMulti = lazy(() => import('./components/DropMulti'));

const Escape = lazy(() => import('./components/Escape'));
const Drop2 = lazy(() => import('./components/Drop2'));
//const GenerativeTest = lazy(() => import('./components/GenerativeTest'));

const GenerativeDisabeld = lazy(() => import('./components/GenerativeDisabled'));
//const Generative = lazy(() => import('./components/Generative'));


const TEST = false;
const TESTNET = { url: 'https://ithacanet.ecadinfra.com', NetworkType: "ithacanet", FA2: "KT19TwytZV4ZQGYWKxJTaBp2aih7jdFSr16h", MARKETPLACE: "KT1SnLziRzqrpoLrMkBiPqfipv15jhGe2N1y" }
const MAINNET = { url: "https://mainnet.api.tez.ie", NetworkType: NetworkType.MAINNET, FA2: "KT1S23ui1PKU5G3V52Ds2NyNnPgxJbZhUY6y", MARKETPLACE: "KT1BJaN9oY2SuUzwACxSegGJynkrRbQCEEfX" }
const CONSTANTS = TEST ? TESTNET : MAINNET;

const Tezos = new TezosToolkit(CONSTANTS.url);
const wallet = new BeaconWallet({ name: "C-VERSO Client" });
Tezos.setWalletProvider(wallet);
wallet.client.getActiveAccount()
  .then((account) => {
    console.log("We are connected to the account : ");
    console.log(account);
  }).catch((err) => {
    console.log("We couldn't retrieve the address");
    console.log(err);

  });

//aquire data from json file
let data = require("./data.json");
let url = `https://path-of-art2.herokuapp.com/`

function App() {
  const [address, setAddress] = useState(undefined);

  /*   useCalculateAttributes(data.drops);
   */
  useEffect(() => {
    async function fetchAddress() {
      try {
        let account = await wallet.client.getActiveAccount()
        setAddress(account?.address);
      } catch (e) {
        console.log(e);
      }

    }
    !address && fetchAddress();
  }, [address]);



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className="App">
        {/* top navigation bar */}
        <div className="App-header">
          <Navigator drops={data.drops} address={address} setAddress={setAddress} wallet={wallet} />
        </div>

        <div className="App-body">
          <Suspense fallback={<Box key="fallback" m={50}></Box>}>
            {/* load the page corresponding on the url */}
            <Routes>

              {/* navigation bar menu */}
              <Route path="/" element={<Home data={data} address={address} FA2={CONSTANTS.FA2} />} />
              <Route path="artist" element={<Artist drops={data.drops} />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="collected" element={<Collected address={address} />} />
              <Route path="events" element={<Events />} />
              {/*               <Route path="/explore" element={<MarketplaceHome drops={data.drops} MARKETPLACE={CONSTANTS.MARKETPLACE} FA2={CONSTANTS.FA2} />} />
 */}

              {/* drops pages */}


              <Route path="/demo" element={<Generative {...data.drops[14]} wallet={wallet} Tezos={Tezos} MARKETPLACE={CONSTANTS.MARKETPLACE} FA2={CONSTANTS.FA2} address={address} />} />


              <Route path={data.drops[6].url} element={<DropMulti {...data.drops[6]} wallet={wallet} Tezos={Tezos} MARKETPLACE={CONSTANTS.MARKETPLACE} FA2={CONSTANTS.FA2} address={address} />} />



              {data.drops.map((drop, index) => {
                if (drop.id >= 15) return null
                return (drop.type === 0 ?


                  <Fragment key={drop.url} >
                    <Route path={drop.url} element={<GenerativeCompleted {...drop} wallet={wallet} Tezos={Tezos} MARKETPLACE={CONSTANTS.MARKETPLACE} FA2={CONSTANTS.FA2} address={address} />} />
                    <Route path={drop.url + "/:id"} element={<GenerativeCompleted {...drop} wallet={wallet} Tezos={Tezos} MARKETPLACE={CONSTANTS.MARKETPLACE} FA2={CONSTANTS.FA2} address={address} />} />
                    <Route path={drop.url + "/marketplace"} element={<Marketplace {...drop} drops={data.drops} wallet={wallet} Tezos={Tezos} MARKETPLACE={CONSTANTS.MARKETPLACE} FA2={CONSTANTS.FA2} address={address} />} />
                    <Route path={drop.url + "/show"} element={<GenerativeShow id={drop.id} />} />

                  </Fragment>


                  :

                  <Route key={drop.url} path={drop.url} element={<Drop {...drop} wallet={wallet} Tezos={Tezos} MARKETPLACE={CONSTANTS.MARKETPLACE} FA2={CONSTANTS.FA2} address={address} />} />

                );

              })}


              {/* default page */}
              <Route path="*" element={<Home data={data} />} />
            </Routes>
          </Suspense>
        </div>
        {/*always load footer on every page */}
        <Footer />
      </div>
    </ThemeProvider >
  );
}

export default App;


