/**
 * 
 * @param {*} wallet the address of the wallet
 * @returns a shorted address
 * @description this function is used to shorten the address of the wallet
 */
export const walletPreview = (wallet) => {
  try {
    return `${wallet.slice(0, 5)}...${wallet.slice(
      wallet.length - 2,
      wallet.length
    )}`;
  } catch (e) {
    return '';
  }
}
/**
 * 
 * @param {*} minted the current minted value
 * @param {*} total  the total minted possible
 * @returns a number between 0 and 100 that represents the percentage of the total minted
 * @description ORIGINAL FORMULA  : 
 * const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);
 * MIN = Minimum expected value
 * MAX = Maximium expected value
 * Function to normalise the values (MIN / MAX could be integrated)
 */

export const normalise = (minted, total) => ((minted - 0) * 100) / (total - 0);




/**
 * 
 * @param {*} canvases a list of canvases to be merged
 * @returns a canvas with the images merged
 * @description When you have a list of canvases, you can use this function to get a combined images 
 */
export function combineCanvases(canvases) {
  const combined = document.createElement("canvas");
  combined.setAttribute("width", String(canvases[0].width));
  combined.setAttribute("height", String(canvases[0].height));
  const ctx = combined.getContext("2d");
  for (const canvas of canvases) {
    ctx.drawImage(canvas, 0, 0);
  }
  return combined;
}


export function ipfs2pinata(ipfs) {
  return `https://cverso.mypinata.cloud/ipfs/${ipfs.split('ipfs://')[1]}`;
}

export function id2objkt(id) {
  return `https://objkt.com/asset/c-verso/${id}`;
}

export function getTezosAmount(amount) {
  return (parseFloat(amount) / 1000000).toFixed(0);
}



export const fullscreen = (element) => {
  try {
    let elem = document.querySelector(element || "iframe");
    if (!document.fullscreenElement) {
      if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen({ navigationUI: "hide" });
        return
      }
      if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen({ navigationUI: "hide" });
        return
      }
      if (elem.requestFullscreen) {
        elem.requestFullscreen({ navigationUI: "hide" });
        return
      }

    } else {

      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        return
      }
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        return
      }
      if (document.exitFullscreen) {
        document.exitFullscreen();
        return
      }
    }

  } catch (error) {
    console.log(error);

  }
}

