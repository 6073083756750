import { Avatar, Button, Container, Divider, Grid, IconButton, Link, Paper, Stack, Typography, Box, Tab } from "@mui/material";
import { TabList, TabContext, TabPanel } from '@mui/lab';

import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import useGraphQLObjktQueries from '../hooks/useGraphQLObjktQueries';
import { useQuery } from '@apollo/client';
import { id2objkt, ipfs2pinata, getTezosAmount } from "../utils";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ExploreIcon from '@mui/icons-material/Explore';

import { LazyLoadImage } from "react-lazy-load-image-component";
import MarketplaceBackdrop from "./MarketplaceBackdrop";

export default function Marketplace(props) {
    let { drops, attributes, interactive, name, artist, id, url, published, price, images, description, reserved = "", imagesPerRow, locked, start_drop, total, token_offset, wallet, Tezos, MARKETPLACE, FA2, address, minted, wh_ratio = 1 } = props;

    const { ONSALE, RECENTLY_SOLD, HIGHEST_SOLD } = useGraphQLObjktQueries(total, token_offset, null, name, "asc");
    const { loading, error, data } = useQuery(ONSALE);
    const { loading: loadingRS, error: errorRS, data: dataRS } = useQuery(RECENTLY_SOLD);
    const { loading: loadingHS, error: errorHS, data: dataHS } = useQuery(HIGHEST_SOLD);

    const [average, setAverage] = useState();
    const [max, setMax] = useState();
    const [totalSales, setTotalSales] = useState();

    useEffect(() => {
        return
        if (dataHS?.event.length > 0) {
            //calculate rarities
            const stats = (dataHS.event.reduce((stats, n) => { return { total: stats.total + n.price, min: Math.min(stats.min, n.price), max: Math.max(stats.max, n.price) } }, { total: 0, min: 0, max: 0 }))
            setAverage(stats.total / dataHS.event.length)
            setMax(stats.max)
            setTotalSales(stats.total)
        }


    }, [dataHS])


    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [openMarketMenu, setOpenMarketMenu] = useState(false);
    const handleClose = () => {
        setOpenMarketMenu(false);
    };


    return (
        <>
            {/* banner */}
            <div className='home-header'>
                <div ><h1> </h1>
                </div>
            </div>

            <Container sx={{ mt: 5 }} >

                <MarketplaceBackdrop drops={drops} open={openMarketMenu} handleClose={handleClose}></MarketplaceBackdrop>

                <Stack spacing={12} direction={"row"} justifyContent="space-between">


                    <IconButton sx={{ color: "primary.main" }} component={RouterLink} to={url}>
                        <ArrowBackIcon />
                        <Typography> to collection</Typography>
                    </IconButton>

                    <IconButton viewBox="0 0 50 200" sx={{ color: "primary.main" }} onClick={() => setOpenMarketMenu(true)} >
                        <Typography> select a collection</Typography>

                        <ExploreIcon />


                    </IconButton>
                </Stack>

                <Container sx={{ mb: 5 }} maxWidth="md">

                    <Grid container justifyContent="space-around" alignItems='center'>
                        <Grid item xs={6}>

                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                #{id}
                            </Typography>
                            <Typography className="titolo-opera-interno" variant="h3" >{name}</Typography>
                            <Stack direction="row" alignItems="start">

                                <Avatar alt={name} src={`/images/${id}/avatar.jpg`} />

                                <Typography className="autore" variant="body1" sx={{ fontWeight: 500 }}>by {artist}</Typography>

                            </Stack>

                        </Grid>
                    </Grid>

                </Container>


                <Divider />

                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <Grid container spacing={4} justifyContent="center" alignItems='center'>
                        <Grid item sx={2} >
                            <Typography>Items</Typography>
                            <Typography textAlign='center'>{total}</Typography>
                        </Grid> <Grid item sx={2} >
                            <Typography>For Sale</Typography>
                            <Typography textAlign='center'>{data?.token?.length}</Typography>
                        </Grid>
                        {/*                         <Grid item sx={2} >
                            <Typography>Secondary sales</Typography>
                            <Typography textAlign='center'>{getTezosAmount(totalSales)}ꜩ</Typography>
                        </Grid> */}

                        <Grid item sx={2} >
                            <Typography>Mint price</Typography>
                            <Typography textAlign='center'>{price}ꜩ</Typography>
                        </Grid>
                        <Grid item sx={2} >
                            <Typography>Floor Price</Typography>
                            <Typography textAlign='center'>{getTezosAmount(data?.token[0].lowest_ask)} ꜩ </Typography>
                        </Grid>
                        {/* 
                        <Grid item sx={2} >
                            <Typography>Higest sold</Typography>
                            <Typography>{getTezosAmount(dataHS?.event[0]?.price)}ꜩ</Typography>
                        </Grid> */}
                        {/*                         <Grid item sx={2} >
                            <Typography>Lowest sold</Typography>
                            <Typography textAlign='center'>{getTezosAmount(dataHS?.event?.at(-1).price)}ꜩ</Typography>
                        </Grid> */}

                        {/* 
                        <Grid item sx={2} >
                            <Typography>Average sold</Typography>
                            <Typography textAlign='center'>{getTezosAmount(average)} ꜩ </Typography>
                        </Grid>
 */}

                    </Grid>

                </Box>
                <Divider />

                <Box sx={{ width: '100%', typography: 'body1', mt: 5 }}>
                    <TabContext value="2">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList centered onChange={handleChange} aria-label="markeplace tabs">
                                <Tab label="Recently sold" value="1" />
                                <Tab label="For sale" value="2" />
                                <Tab label="Rarest" value="3" disabled={!attributes} />

                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <div className="collection-gallery">
                                <Grid container className="collection-gallery-imagelist">
                                    {(dataRS?.event.length > 0) && dataRS.event.map(
                                        (item, index) => {
                                            return (
                                                <Grid item key={index} xs={12} sm={6} md={12 / imagesPerRow}>

                                                    <Box className="collection-gallery-image" >
                                                        <Paper component={LazyLoadImage} alt="" src={ipfs2pinata(item.token.thumbnail_uri)} square elevation={6} style={{ maxWidth: '100%', overflow: 'auto' }} className="collection-gallery-image" />



                                                        {/*                 <iframe title={item.token.name}

                                                            height="100%"
                                                            width="100%"

                                                            src={ipfs2pinata(item.token.artifact_uri)}
                                                            alt=""
                                                        /> */}

                                                        <Typography className="titolo-minted" variant="body2"> {getTezosAmount(item.price) + "ꜩ"}</Typography>

                                                        <Button className="bottoni-interni-minted" target="_blank" component={Link} href={"https://objkt.com/asset/c-verso/" + item.token.token_id}>
                                                            trade
                                                        </Button>

                                                    </Box>
                                                </Grid>
                                            )
                                        }
                                    )}
                                </Grid>
                            </div>

                        </TabPanel>

                        <TabPanel value="2">
                            <div className="collection-gallery">

                                <Grid container className="collection-gallery-imagelist">
                                    {(data?.token.length > 0) && data.token.map(
                                        (item, index) => {
                                            return (
                                                <Grid item key={index} xs={12} sm={6} md={12 / imagesPerRow}>

                                                    <Box className="collection-gallery-image" >

                                                        <Paper component={LazyLoadImage} alt="" src={ipfs2pinata(item.thumbnail_uri)} square elevation={6} style={{ maxWidth: '100%', overflow: 'auto' }} className="collection-gallery-image" />

                                                        <Typography className="titolo-minted" variant="body2"> {getTezosAmount(item.lowest_ask) + "ꜩ"}</Typography>

                                                        <Button className="bottoni-interni-minted" target="_blank" component={Link} href={"https://objkt.com/asset/c-verso/" + item.token_id}>
                                                            buy
                                                        </Button>
                                                        {/* <Button className="bottoni-interni-minted" target="_blank">
                                                        live
                                                    </Button> */}
                                                    </Box>
                                                </Grid>
                                            )
                                        }
                                    )}
                                </Grid>
                            </div>

                        </TabPanel>
                        <TabPanel value="3">rarities coming next week</TabPanel>

                    </TabContext>
                </Box>
            </Container >
        </>
    )
}