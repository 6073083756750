import { createTheme } from "@mui/material";

export const theme = createTheme({

    palette: {
        mode: "light",
        primary: {
            main: "#005266",
        },
        secondary: {
            main: "#f50057",
        },
        background: {
            default: "#ECF2ED",
            paper: "#ECF2ED",
            paperArtist: "#e9eae6",
        },
    },
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),

    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    }
});
