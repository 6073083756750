import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import "../index.css"
import Typography from '@mui/material/Typography';
import CategoryIcon from '@mui/icons-material/Category';
import { listItemSecondaryActionClasses } from '@mui/material';

export default function Events() {


    ///TODO: get input from event data, render 3 type of TimelineItem based on current date (past, present,future)
    return (
        <>
            {/* banner */}
            <div className='home-header'><div ><h1> Events</h1></div></div>

            <Timeline position="alternate">

                <PastYearEvent year="2022" />

                <PastEvent date="May 2022" location="Artefiera Bologna" artists="Stefano Contiero, Ben Kovach, ippsketch" />
                <PastEvent date="June 2022" location="Salone del mobile, Milan" artists="ALT+ESC, Anna Lucia" />
                <PastEvent date="July 2022" location="Cortesi Gallery, Milan" artists="Nadieh Bremer, Anna Carreras, Eko33, Julien Gachadoat" />

                <PastEvent date="8-11 September 2022" location="The Armory Show, New York" artists="Rich Poole, Emily Xie, Zancan, Ben Kovach" />

                <PastEvent date="16 September 2022" location="Withers, Milan" artists="Michael Connoly" />
                <PastEvent date="22-25 September 2022" location="Wopart Lugano, Switzerland" artists="ippsketch" />
                {/* <CurrentEvent date="December 2022" location="Miami, USA" artists="TBD" /> */}
                <PastYearEvent year="2023" />
                <FinalEvent />

            </Timeline>
        </>
    );
}


const PastEvent = ({ date, location, artists }) => {
    return (<TimelineItem>
        <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
        >
            {date}
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
            <TimelineDot color="primary" variant="outlined" >
                <CategoryIcon />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
                {location}
            </Typography>
            <Typography> {artists}</Typography>
        </TimelineContent>
    </TimelineItem>)
}

const CurrentEvent = ({ date, location, artists }) => {
    return (
        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
            >
                <Typography color="primary.main" >
                    {date}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                <TimelineDot color="primary" >
                    <CategoryIcon />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span" color="primary.main">{location}</Typography>
                <Typography>{artists}</Typography>
            </TimelineContent>
        </TimelineItem>
    )
}

const FutureEvent = ({ date, location, artists }) => {
    return (
        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
            >
                {date}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot >
                    <CategoryIcon />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {location}
                </Typography>
                <Typography>{artists}</Typography>
            </TimelineContent>
        </TimelineItem>
    )
}


// this is a transition to a new year, just showing the year
const PastYearEvent = ({ year }) => {
    return (
        <TimelineItem>
            <TimelineSeparator>
                <Typography variant='caption'>{year}</Typography>
                <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ px: 2 }} />
        </TimelineItem>
    )
}


const FutureYearEvent = ({ year }) => {
    return (
        <TimelineItem>

            <TimelineSeparator>
                <TimelineConnector />
                <Typography variant='caption'>2023</Typography>
                <TimelineConnector />

            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>


            </TimelineContent>
        </TimelineItem>
    )
}

// this is a small dot symbolizing future events
const FinalEvent = () => {
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            </TimelineContent>
        </TimelineItem>
    )
}